import qs from 'qs';
import allowedAttachmentMIMETypes from './allowedAttachmentMIMETypes';
import handleResponse from './handleResponse';
import resourceEndpoints from './resourceEndpoints';

/**
 * ELLA (EAF) microservice client
 *
 * @constructor
 */
export default class MSClientEllaAssignmentFlowEAF {
    constructor(serviceParameters = {}) {
        this.serviceParameters = {
            ...serviceParameters,
            // ensure SERVICE_URL has trailing slash
            SERVICE_URL: typeof serviceParameters.SERVICE_URL === 'string'
                ? serviceParameters.SERVICE_URL.replace(/\/?$/, '/') : '',
        };

        // add SERVICE_URL to each resource endpoint
        this.resourceEndpoints = Object.keys(resourceEndpoints)
            .reduce((parsedResourceEndpoints, endpointKey) => {
                parsedResourceEndpoints[endpointKey] = this.serviceParameters.SERVICE_URL
                    + resourceEndpoints[endpointKey];
                return parsedResourceEndpoints;
            }, {});

        return {
            downloadMultipleFiles: this.downloadMultipleFiles,
            downloadSingleFile: this.downloadSingleFile,
            setAccessToken: this.setAccessToken,
            getInvoiceSubmissions: this.getInvoiceSubmissions,
            sendSupportMessage: this.sendSupportMessage,
            getInvoiceSubmission: this.getInvoiceSubmission,
            updateInvoiceSubmission: this.updateInvoiceSubmission,
            getPricingInfo: this.getPricingInfo,
            updateVehicleData: this.updateVehicleData,
            updateMemberData: this.updateMemberData,
            getAttachmentParameters: this.getAttachmentParameters,
            submitInvoiceSubmission: this.submitInvoiceSubmission,
            uploadFile: this.uploadFile,
            createFollowUpInvoiceSubmission: this.createFollowUpInvoiceSubmission,
            createInvoiceSubmission: this.createInvoiceSubmission,
            getInvoiceSubmissionMessages: this.getInvoiceSubmissionMessages,
            markMessagesAsRead: this.markMessagesAsRead,
            createInvoiceSubmissionMessage: this.createInvoiceSubmissionMessage,
            getPartnerMessages: this.getPartnerMessages,
            updateExternalStatus: this.updateExternalStatus,
            exportInvoiceSubmissions: this.exportInvoiceSubmissions,
            getFiles: this.getFiles,
            getProofOfServicePdf: this.getProofOfServicePdf,
            downloadMultipleReceipts: this.downloadMultipleReceipts,
        };
    }

    accessToken = null;

    exportInvoiceSubmissions = params => {
        const {contractPartnerIds, filters, fileType, fileExtension} = params;
        const {sort, filters: appliedFilters} = filters || {};
        const {mainServices, date, statuses} = appliedFilters || {};

        const queryObject = {
            contractPartnerIds,
            fileType,
            ...(statuses.length ? {statuses} : {statuses: ['BOOKED', 'PAID', 'EXTERNAL_INVOICING', 'PAYMENT_DECLINED']}),
            ...(mainServices && {mainServices}),
            ...(sort && {sort}),
            ...(date || {}),
        };

        const queryString = qs.stringify(queryObject, {
            arrayFormat: 'brackets',
        });
        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}/export?${queryString}`;
        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const fileName = `archive_export${fileExtension}`;
                link.setAttribute('download', `${fileName}`);
                link.target = '_blank';
                link.type = allowedAttachmentMIMETypes;
                document.body.appendChild(link);
                link.click();
                link.remove();

                return blob;
            });
    };

    downloadSingleFile = params => {
        const {fileName, path} = params;
        const queryString = qs.stringify({path}, {
            arrayFormat: 'brackets',
        });
        const apiUrl = `${this.resourceEndpoints['FILES']}/download?${queryString}`;
        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileName}`);
                link.target = '_blank';
                link.type = allowedAttachmentMIMETypes;
                document.body.appendChild(link);
                link.click();
                link.remove();

                return blob;
            });
    };

    downloadMultipleFiles = params => {
        const {paths} = params;
        const queryString = qs.stringify({paths}, {
            arrayFormat: 'repeat',
        });
        const apiUrl = `${this.resourceEndpoints['FILES']}/download/multiple?${queryString}`;
        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `selectedDocuments`);
                link.target = '_blank';
                link.type = allowedAttachmentMIMETypes;
                document.body.appendChild(link);
                link.click();
                link.remove();

                return blob;
            });
    };

    getInvoiceSubmissions = params => {
        const queryParams = new URLSearchParams();

        if (typeof params === 'object' && Object.keys(params).length > 0) {
            Object.keys(params).forEach(key => {
                queryParams.append(`${key}`, params[key]);
            });
        }
        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}?${queryParams.toString()}`;
        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse);
    };

    getInvoiceSubmission = params => {
        const {invoiceSubmissionId, shouldSetInEditing} = params;
        const query = {
            ...(shouldSetInEditing && {pushInEditing: true}),
        };
        const queryString = qs.stringify(query, {
            arrayFormat: 'brackets',
        });

        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}/${invoiceSubmissionId}?${queryString}`;
        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse);
    };

    updateInvoiceSubmission = params => {
        const {invoiceSubmissionId, data, isNoValidation} = params;
        const query = {
            ...(isNoValidation && {noValidation: true}),
        };
        const queryString = qs.stringify(query, {
            arrayFormat: 'brackets',
        });
        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}/${invoiceSubmissionId}?${queryString}`;
        return fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify(data),
        })
            .then(handleResponse);
    };

    updateVehicleData = params => {
        const {invoiceSubmissionId, data, isNoValidation} = params;
        const query = {
            ...(isNoValidation && {noValidation: true}),
        };
        const queryString = qs.stringify(query, {
            arrayFormat: 'brackets',
        });
        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}/${invoiceSubmissionId}/vehicle?${queryString}`;
        return fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify(data),
        })
            .then(handleResponse);
    };

    updateMemberData = params => {
        const {invoiceSubmissionId, data, isNoValidation} = params;
        const query = {
            ...(isNoValidation && {noValidation: true}),
        };
        const queryString = qs.stringify(query, {
            arrayFormat: 'brackets',
        });
        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}/${invoiceSubmissionId}/personnel?${queryString}`;
        return fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify(data),
        })
            .then(handleResponse);
    };

    sendSupportMessage = params => {
        const {data} = params;
        const apiUrl = this.resourceEndpoints['SUPPORT'];

        return fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify(data),
        })
            .then(handleResponse);
    };

    getPricingInfo = params => {
        const queryString = qs.stringify(params, {
            arrayFormat: 'brackets',
        });
        const apiUrl = `${this.resourceEndpoints['PRICING']}?${queryString}`;

        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse);
    };

    getAttachmentParameters = params => {
        const queryString = qs.stringify(params, {
            arrayFormat: 'brackets',
        });

        const apiUrl = `${this.resourceEndpoints['ATTACHMENTS_UPLOAD']}?${queryString}`;

        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse);
    };

    submitInvoiceSubmission = params => {
        const {invoiceSubmissionId, externalDocumentNumber} = params;
        const data = {
            ...(!!externalDocumentNumber && {externalDocumentNumber}),
        };
        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}/${invoiceSubmissionId}/request-crediting`;
        return fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify(data),
        })
            .then(handleResponse);
    };

    uploadFile = params => {
        const {file, url, contentType} = params;

        return fetch(
            url,
            {
                headers: {
                    'Content-Type': contentType,
                    'Accept': 'application/xml',
                    'x-ms-blob-type': 'BlockBlob',
                },
                method: 'PUT',
                body: file,
            },
        )
            .then(handleResponse);
    };

    createFollowUpInvoiceSubmission = params => {
        const {invoiceSubmissionId} = params;

        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}/${invoiceSubmissionId}`;
        return fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse);
    };

    createInvoiceSubmission = params => {
        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}`;
        return fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify(params),
        })
            .then(handleResponse);
    };

    getInvoiceSubmissionMessages = params => {
        const {invoiceSubmissionId} = params;
        const queryString = qs.stringify({invoiceSubmissionId, direction: 'ASC'}, {
            arrayFormat: 'brackets',
        });
        const apiUrl = `${this.resourceEndpoints['MESSAGES']}?${queryString}`;
        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse);
    };

    getPartnerMessages = params => {
        const {contractPartnerIds, lastCreatedAfter, size, originator} = params;
        const queryString = qs.stringify({contractPartnerIds, size, lastCreatedAfter, originator}, {
            arrayFormat: 'brackets',
        });
        const apiUrl = `${this.resourceEndpoints['MESSAGES']}/notifications?${queryString}`;
        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse);
    };

    markMessagesAsRead = params => {
        const {messageIds} = params;
        const apiUrl = `${this.resourceEndpoints['MESSAGES']}`;
        return fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify({messageIds}),
        })
            .then(handleResponse);
    };

    createInvoiceSubmissionMessage = params => {
        const {invoiceSubmissionId, text, author} = params;
        const apiUrl = `${this.resourceEndpoints['MESSAGES']}`;
        return fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify({invoiceSubmissionId, text, author}),
        })
            .then(handleResponse);
    };

    updateExternalStatus = params => {
        const {enable, invoiceSubmissionId} = params;

        const queryString = qs.stringify({enable}, {
            arrayFormat: 'brackets',
        });

        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}/${invoiceSubmissionId}/external-invoicing?${queryString}`;
        return fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse);
    };

    getFiles = params => {
        const {contractPartners} = params;
        const queryString = qs.stringify({contractPartners}, {
            arrayFormat: 'brackets',
        });
        const apiUrl = `${this.resourceEndpoints['FILES']}?${queryString}`;
        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse);
    };

    setAccessToken = accessToken => {
        this.accessToken = accessToken;
    };

    getProofOfServicePdf = params => {
        const {invoiceSubmissionId} = params;
        const apiUrl = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}/${invoiceSubmissionId}/proof-of-services`;

        return fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${invoiceSubmissionId}`);
                link.target = '_blank';
                link.type = allowedAttachmentMIMETypes;
                document.body.appendChild(link);
                link.click();
                link.remove();

                return blob;
            });
    };

    downloadMultipleReceipts = ({invoiceSubmissionIds}) => {
        const queryParams = new URLSearchParams();
        queryParams.append('invoiceSubmissionIds', invoiceSubmissionIds);
        const apiURL = `${this.resourceEndpoints['INVOICE_SUBMISSIONS']}/receipt/download?${queryParams.toString()}`;

        return fetch(apiURL, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'selectedReceipts');
                link.target = '_blank';
                link.type = allowedAttachmentMIMETypes;
                document.body.appendChild(link);
                link.click();
                link.remove();

                return blob;
            });
    };
}

