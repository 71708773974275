import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DatePicker, {registerLocale} from 'react-datepicker';
import de from 'date-fns/locale/de';
import './AceDatePicker.scss';
import Icon from '../icon/Icon';

const maxPossibleDate = new Date(8630000000000000);
const minPossibleDate = new Date(-8630000000000000);

registerLocale('de', de);

const AceDatePicker = ({
    isButtonInput,
    buttonInputLabel,
    label,
    dateFormat,
    selected,
    isDisabled,
    onChange,
    placeholderText,
    dateFilterDelete,
    filterName,
    minDate,
    maxDate,
    hasTime,
    filterPassedTime,
    onBlur,
}) => {
    const className = classNames('ace-c-date-picker__field', {
        'ace-c-date-picker__field--button-style': isButtonInput,
        'ace-c-date-picker__field--input-style': !isButtonInput,
        'ace-c-date-picker__field--input-style-disabled': isDisabled,
    });

    const popperClassName = classNames('ace-c-date-picker__popper', {
        'ace-c-date-picker__popper--button-style': isButtonInput,
        'ace-c-date-picker__popper--input-style': !isButtonInput,
    });

    const iconClassName = classNames('ace-c-date-picker__calendar-icon', {
        'ace-c-date-picker__calendar-icon--button-style': isButtonInput,
        'ace-c-date-picker__calendar-icon--input-style': !isButtonInput,
    });

    const calendarClassName = classNames('ace-c-date-picker__calendar', {
        'ace-c-date-picker__calendar--button-style': isButtonInput,
        'ace-c-date-picker__calendar--input-style': !isButtonInput,
    });
    const closeClassName = classNames('ace-c-date-picker__close-icon', {
        'ace-c-date-picker__close-icon--disabled': !selected,
    });

    const fill = isDisabled ? '' : 'red';
    const closeIconFill = selected ? 'red' : '';

    const handleOnBlur = event => {
        if (typeof onBlur === 'function') onBlur(event.target.value);
    };

    return (
        <div className="ace-c-date-picker__wrapper">
            <div className="ace-c-date-picker__label">
                {label}
            </div>
            <DatePicker
                className={className}
                calendarClassName={calendarClassName}
                popperClassName={popperClassName}
                locale="de"
                autoComplete="off"
                selected={selected}
                dateFormat={dateFormat}
                onChange={onChange}
                placeholderText={placeholderText}
                minDate={minDate || minPossibleDate}
                maxDate={maxDate || maxPossibleDate}
                showTimeSelect={hasTime}
                filterTime={filterPassedTime}
                onBlur={handleOnBlur}
            />
            <div className={iconClassName}>
                <Icon dimension="lg" iconName="calendar" color={fill} />
            </div>
            {isButtonInput && (
                <Fragment>
                    <span className="ace-c-date-picker__button-label">{buttonInputLabel}</span>
                    <div className={closeClassName} onClick={() => dateFilterDelete(filterName)}>
                        <Icon dimension="md" iconName="close" color={closeIconFill} />
                    </div>
                </Fragment>
            )}
        </div>
    );
};

AceDatePicker.propTypes = {
    isButtonInput: PropTypes.bool,
    isDisabled: PropTypes.bool,
    buttonInputLabel: PropTypes.string,
    label: PropTypes.string,
    dateFormat: PropTypes.any,
    selected: PropTypes.any,
    onChange: PropTypes.func,
    placeholderText: PropTypes.string,
    dateFilterDelete: PropTypes.func,
    filterName: PropTypes.string,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    hasTime: PropTypes.bool,
    filterPassedTime: PropTypes.func,
    onBlur: PropTypes.func,
};

AceDatePicker.defaultProps = {
    isButtonInput: false,
    isDisabled: false,
    buttonInputLabel: '',
    label: '',
    onChange: () => {},
    dateFormat: '',
    selected: new Date(),
    placeholderText: '',
    dateFilterDelete: null,
    filterName: '',
    minDate: null,
    maxDate: null,
    hasTime: false,
    filterPassedTime: null,
    onBlur: null,
};


export default AceDatePicker;

