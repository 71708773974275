import {immerable} from 'immer';
import moment from 'moment';
import Entity from '../entity/Entity';
import Damage from './Damage';
import {Member} from '../member';
import {ContractPartner} from '../contract-partner';
import {Vehicle} from '../vehicle';
import Towing from './Towing';
import PickUp from './PickUp';
import AssignmentId from './AssignmentId';
import {processTime} from '../utils/times';
import {JourneyContinuation} from '../journey-continuation';

/**
 * Assignment entity type definition
 *
 * @typedef {Object} Assignment
 * @property {?string} assignmentId
 * @property {?string} driverId
 * @property {?string} status
 * @property {?Damage} damage
 * @property {?boolean} isAccident
 * @property {?boolean} hasTrailer
 * @property {?string} journeyContinuation
 * @property {?Array} mainServices
 * @property {?string} mainServicesComment
 * @property {?Member} member
 * @property {?Vehicle} vehicle
 * @property {?Towing} towing
 * @property {?ContractPartner} contractPartner
 * @property {?string} emergencyCallDateTime
 * @property {?string} serviceStartDateTime
 * @property {?string} serviceEndDateTime
 * @property {?string} startOfRecovery
 * @property {?string} endOfRecovery
 * @property {?string} additionalText
 * @property {?string} assignmentText
 * @property {?string} startDate
 * @property {?string} endDate
 * @property {?string} serviceType
 * @property {?string} serviceText
 * @property {?string} driverFirstName
 * @property {?string} driverLastName
 * @property {?string} ongoingServiceType
 * @property {?string} fallbackSystemId
 */

/**
 * Assignment entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const assignmentEntityDTOMapping = {
    assignmentId: {
        dtoProperty: 'assignmentId',
        fromDTO: id => encodeURIComponent(id),
    },
    id: {
        dtoProperty: 'id',
        defaultValue: new AssignmentId(),
        entity: AssignmentId,
    },
    prefix: 'prefix',
    damage: {
        dtoProperty: 'damage',
        defaultValue: new Damage(),
        entity: Damage,
    },
    member: {
        dtoProperty: 'member',
        defaultValue: new Member(),
        entity: Member,
    },
    vehicle: {
        dtoProperty: 'vehicle',
        defaultValue: new Vehicle(),
        entity: Vehicle,
    },
    towing: {
        dtoProperty: 'towing',
        defaultValue: new Towing(),
        entity: Towing,
    },
    pickup: {
        dtoProperty: 'pickup',
        defaultValue: new PickUp(),
        entity: PickUp,
    },
    startOfRecovery: {
        dtoProperty: 'startOfRecoveryDateTime',
        fromDTO: date => (date ? moment(date) : moment().hours(0).minutes(0)),
    },
    endOfRecovery: {
        dtoProperty: 'endOfRecoveryDateTime',
        fromDTO: date => (date ? moment(date) : moment().hours(0).minutes(0)),
    },
    isAccident: {
        dtoProperty: 'isAccident',
        defaultValue: false,
    },
    hasTrailer: {
        dtoProperty: 'hasTrailer',
        defaultValue: false,
    },
    journeyContinuation: {
        dtoProperty: 'journeyContinuation',
        defaultValue: JourneyContinuation.YES,
    },
    billingType: 'billingType',
    updatedAt: {
        dtoProperty: 'updatedAt',
        fromDTO: date => (date ? moment(date) : null),
    },
    emergencyCallDateTime: {
        dtoProperty: 'emergencyCallDateTime',
        fromDTO: date => (date ? moment(date) : null),
    },
    serviceStartDateTime: {
        dtoProperty: 'serviceStartDateTime',
        fromDTO: date => (date ? moment(date) : null),
    },
    serviceEndDateTime: {
        dtoProperty: 'serviceEndDateTime',
        fromDTO: date => (date ? moment(date) : null),
    },
    contractPartnerInfo: {
        dtoProperty: 'contractPartnerInfo',
        defaultValue: new ContractPartner(),
        entity: ContractPartner,
    },
    emptyTripReason: {
        dtoProperty: 'emptyTripReason',
        defaultValue: '',
    },
    isFollowUpInvoiceSubmissionAllowed: {
        dtoProperty: 'followUpInvoiceSubmissionAllowed',
        defaultValue: false,
    },
    isMoaCase: {
        dtoProperty: 'isMoaCase',
        defaultValue: false,
    },
    fallbackSystemId: {
        dtoProperty: 'fallbackSystemId',
        defaultValue: '',
    },
};

/**
 * Assignment entity
 *
 * @class
 * @extends Entity
 * @type {Assignment}
 */
export default class Assignment extends Entity {
    [immerable] = true;

    static entityDTOMapping = assignmentEntityDTOMapping;

    static extendEntityDTOMapping = entityDTOMapping => {
        return {
            ...assignmentEntityDTOMapping,
            ...entityDTOMapping,
        };
    };

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }

    get startDate() {
        return this.serviceStartDateTime || this.emergencyCallDateTime;
    }

    get endDate() {
        return this.serviceEndDateTime || this.startDate;
    }

    get startTime() {
        return {
            ...processTime(this.startDate, !this.startDate),
        };
    }

    get endTime() {
        return {
            ...processTime(this.endDate, !this.endDate),
        };
    }

    get recoveryStart() {
        return {
            ...processTime(this.startOfRecovery, !this.startOfRecovery),
        };
    }

    get recoveryEnd() {
        return {
            ...processTime(this.endOfRecovery, !this.endOfRecovery),
        };
    }
}
