import {take, put, fork, cancel, select} from 'redux-saga/effects';
import {push, resolveRoute} from '@computerrock/formation-router';
import * as errorActionTypes from '../../application/errorActionTypes';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as userProfileSelectors from '../../user-management/userManagementSelectors';
import routePaths from '../../routePaths';
import {parseErrorMessages} from '../../utils/validationMessages';
import {createInvoiceSubmissionStatuses} from '../constants/createInvoiceSubmission';
import fetchRequest from '../../application/sagas/fetchRequest';
import {InvoiceSubmissionDataStates} from '../constants/invoiceSubmissionDataStates';

/**
 * Create damage event flow
 */
const defaultCreateISState = {
    createInvoiceSubmissionState: createInvoiceSubmissionStatuses.IDLE,
    isMemberIdFound: null,
    errors: {},
};

const createInvoiceSubmissionFlow = function* () {
    while (true) {
        yield take(invoiceSubmissionActionTypes.START_CREATE_INVOICE_SUBMISSION_FLOW);
        yield put({
            type: invoiceSubmissionActionTypes.STORE_CREATE_INVOICE_SUBMISSION_STATUS,
            payload: {
                createInvoiceSubmissionState: createInvoiceSubmissionStatuses.STARTED,
                isMemberIdFound: null,
            },
        });
        const createISFlow = yield fork(createInvoiceSubmissionFlowSteps);

        yield take(invoiceSubmissionActionTypes.END_CREATE_INVOICE_SUBMISSION_FLOW);
        yield cancel(createISFlow);
        yield put({
            type: invoiceSubmissionActionTypes.STORE_CREATE_INVOICE_SUBMISSION_STATUS,
            payload: defaultCreateISState,
        });
    }
};

const createInvoiceSubmissionFlowSteps = function* () {
    const {serviceManager} = yield select(state => state.application);
    const memberProfileService = serviceManager.loadService('memberProfileService');
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    while (true) {
        const checkMemberStatusAction = yield take(invoiceSubmissionActionTypes.SUBMIT_CHECK_MEMBER_STATUS_FORM);
        yield put({
            type: invoiceSubmissionActionTypes.STORE_CREATE_INVOICE_SUBMISSION_STATUS,
            payload: {
                createInvoiceSubmissionState: createInvoiceSubmissionStatuses.MEMBERSHIP_CHECK_PENDING,
                isMemberIdFound: null,
            },
        });

        const {memberId} = checkMemberStatusAction.payload;

        yield fork(fetchRequest,
            invoiceSubmissionActionTypes.FETCH_MEMBER_STATUS_REQUEST,
            memberProfileService.getMemberById, {
                memberId,
            });

        const resultAction = yield take([
            invoiceSubmissionActionTypes.FETCH_MEMBER_STATUS_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.FETCH_MEMBER_STATUS_REQUEST_FAILED,
        ]);

        if (resultAction.type === invoiceSubmissionActionTypes.FETCH_MEMBER_STATUS_REQUEST_FAILED) {
            yield put({
                type: invoiceSubmissionActionTypes.STORE_CREATE_INVOICE_SUBMISSION_STATUS,
                payload: {
                    createInvoiceSubmissionState: createInvoiceSubmissionStatuses.STARTED,
                    isMemberIdFound: false,
                },
            });
        }
        if (resultAction.type === invoiceSubmissionActionTypes.FETCH_MEMBER_STATUS_REQUEST_SUCCEEDED) {
            yield put({
                type: invoiceSubmissionActionTypes.STORE_CREATE_INVOICE_SUBMISSION_STATUS,
                payload: {
                    createInvoiceSubmissionState: createInvoiceSubmissionStatuses.STARTED,
                    isMemberIdFound: true,
                },
            });
        }

        while (true) {
            const createInvoiceSubmissionAction = yield take(invoiceSubmissionActionTypes
                .SUBMIT_CREATE_INVOICE_SUBMISSION_FORM);
            yield put({
                type: invoiceSubmissionActionTypes.STORE_CREATE_INVOICE_SUBMISSION_STATUS,
                payload: {
                    createInvoiceSubmissionState: createInvoiceSubmissionStatuses.INVOICE_SUBMISSION_PENDING,
                    isMemberIdFound: null,
                },
            });

            const {memberId} = createInvoiceSubmissionAction.payload;
            const userAccount = yield select(userProfileSelectors.getUserAccount);


            yield fork(fetchRequest, invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_REQUEST,
                eafService.createInvoiceSubmission,
                {
                    membershipNumber: memberId,
                    contractPartnerId: userAccount.partnerIds[0],
                });
            const createInvoiceSubmissionResult = yield take([
                invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
                invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_REQUEST_FAILED,
            ]);


            if (createInvoiceSubmissionResult.type === invoiceSubmissionActionTypes
                .CREATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED) {
                const {response: invoiceSubmissionDTO} = createInvoiceSubmissionResult.payload;
                yield put({
                    type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
                    payload: {state: InvoiceSubmissionDataStates.LOADING},
                });
                yield put({
                    type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_VIEW_STATE,
                    payload: {
                        invoiceSubmissionViewState: null,
                        formAnchors: null,
                    },
                });
                yield put(push(resolveRoute(routePaths.INVOICE_SUBMISSION,
                    {invoiceSubmissionId: invoiceSubmissionDTO.invoiceSubmissionId},
                    {invoiceSubmissionStatus: invoiceSubmissionDTO.status})));
                yield put({type: invoiceSubmissionActionTypes.END_CREATE_INVOICE_SUBMISSION_FLOW});
                break;
            }

            const errorObject = createInvoiceSubmissionResult.payload;
            yield put({
                type: invoiceSubmissionActionTypes.STORE_CREATE_INVOICE_SUBMISSION_STATUS,
                payload: {
                    createInvoiceSubmissionState: createInvoiceSubmissionStatuses.STARTED,
                    isMemberIdFound: null,
                    errors: parseErrorMessages(errorObject),
                },
            });
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(errorObject),
                    },
                },
            });
            yield put({type: invoiceSubmissionActionTypes.END_CREATE_INVOICE_SUBMISSION_FLOW});
        }
    }
};

export default createInvoiceSubmissionFlow;
