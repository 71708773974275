import {produce} from 'immer';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';
import {createInvoiceSubmissionStatuses} from './constants/createInvoiceSubmission';
import {InvoiceSubmissionDataStates} from './constants/invoiceSubmissionDataStates';
import {submitPopUpStatuses} from './constants/invoiceSubmissionStatuses';
import {InvoiceSubmission} from '../ella-dispo-entity-types/invoice-submission';


const initialState = {
    invoiceSubmissions: {},
    openInvoiceSubmissionIds: [],
    submittedInvoiceSubmissionIds: [],
    archiveInvoiceSubmissionIds: [],
    totalCount: 0,
    createInvoiceSubmissionState: createInvoiceSubmissionStatuses.IDLE,
    isCreateInvoiceSubmissionPreCheckModalOpen: false,
    isCreateInvoiceSubmissionPreCheckSuccess: false,
    createInvoiceSubmissionPreCheckCurrentStep: 0,
    createInvoiceSubmissionPreCheckOptions: [],
    searchedInvoiceSubmissionIds: [],
    searchTerm: '',
    isSearchPending: false,
    isSearchResultsCleared: false,
    isSearchViewActive: false,
    isSearchTermCleared: false,
    invoiceSubmissionViewState: null,
    followUpInvoiceSubmissionMainServices: {},
    submitPopUpStatus: submitPopUpStatuses.CLOSED,
    formAnchor: null,
    pricingInfo: 0,
    openCarMaxPrice: null,
    isPricingDataReady: false,
    invoiceSubmissionDataState: InvoiceSubmissionDataStates.LOADING,
    isDownloadNotificationModalOpen: false,
    totalInvoicesCount: 0,
    serviceFixesPrices: [],
};

const invoiceSubmissionReducer = produce((draft, action) => {
    const {type, payload} = action;
    switch (type) {
        case invoiceSubmissionActionTypes.STORE_OPEN_INVOICE_SUBMISSIONS: {
            const {invoiceSubmissionsDTO} = payload;
            let newCasesCount = 0;
            invoiceSubmissionsDTO.forEach(invoiceSubmissionDTO => {
                const caseId = invoiceSubmissionDTO.invoiceSubmissionId;
                if (invoiceSubmissionDTO.status === 'BILLABLE') {
                    newCasesCount += 1;
                }
                draft.openInvoiceSubmissionIds.push(caseId);
                draft.invoiceSubmissions[caseId] = new InvoiceSubmission().fromDTO(invoiceSubmissionDTO);
            });
            draft.totalCount = newCasesCount;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_SUBMITTED_INVOICE_SUBMISSIONS: {
            const {invoiceSubmissionsDTO} = payload;
            invoiceSubmissionsDTO.forEach(invoiceSubmissionDTO => {
                const caseId = invoiceSubmissionDTO.invoiceSubmissionId;
                draft.submittedInvoiceSubmissionIds.push(caseId);
                draft.invoiceSubmissions[caseId] = new InvoiceSubmission().fromDTO(invoiceSubmissionDTO);
            });
            break;
        }
        case invoiceSubmissionActionTypes.STORE_ARCHIVE_INVOICE_SUBMISSIONS: {
            const {invoiceSubmissionsDTO, totalCount, totalInvoicesCount} = payload;
            invoiceSubmissionsDTO.forEach(invoiceSubmissionDTO => {
                const caseId = invoiceSubmissionDTO.invoiceSubmissionId;
                draft.archiveInvoiceSubmissionIds.push(caseId);
                draft.invoiceSubmissions[caseId] = new InvoiceSubmission().fromDTO(invoiceSubmissionDTO);
            });
            draft.totalCount = totalCount;
            draft.totalInvoicesCount = totalInvoicesCount;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_SEARCH_RESULTS: {
            const {invoiceSubmissionsDTO} = payload;
            invoiceSubmissionsDTO.forEach(invoiceSubmissionDTO => {
                const caseId = invoiceSubmissionDTO.invoiceSubmissionId;
                draft.searchedInvoiceSubmissionIds.push(caseId);
                draft.invoiceSubmissions[caseId] = new InvoiceSubmission().fromDTO(invoiceSubmissionDTO);
            });
            break;
        }
        case invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION: {
            const {invoiceSubmissionDTO} = payload;
            const caseId = invoiceSubmissionDTO.invoiceSubmissionId;
            draft.invoiceSubmissions[caseId] = new InvoiceSubmission().fromDTO(invoiceSubmissionDTO);
            break;
        }
        case invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_VIEW_STATE: {
            const {invoiceSubmissionViewState, formAnchor} = payload;
            draft.invoiceSubmissionViewState = invoiceSubmissionViewState;
            if (formAnchor !== undefined) {
                draft.formAnchor = formAnchor;
            }
            break;
        }
        case invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_SEARCH_STATE: {
            const {searchTerm, isSearchStarted, isSearchingEnded, isSearchResultsCleared} = payload;
            if (isSearchStarted) {
                draft.isSearchPending = true;
                draft.searchTerm = searchTerm;
                draft.isSearchTermCleared = false;
                draft.searchedInvoiceSubmissionIds = [];
                draft.isSearchViewActive = true;
                break;
            }
            if (isSearchingEnded) {
                draft.isSearchPending = false;
                break;
            }
            if (isSearchResultsCleared) {
                draft.isSearchPending = false;
                draft.searchTerm = '';
                draft.isSearchTermCleared = true;
                draft.searchedInvoiceSubmissionIds = [];
                draft.isSearchViewActive = false;
                break;
            }
            break;
        }
        case invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE: {
            const {state} = payload;
            draft.invoiceSubmissionDataState = state;
            break;
        }
        case invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_DATA: {
            draft.totalCount = 0;
            draft.openInvoiceSubmissionIds = [];
            draft.submittedInvoiceSubmissionIds = [];
            draft.archiveInvoiceSubmissionIds = [];
            break;
        }
        case invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_PRE_CHECK_RESET: {
            draft.isCreateInvoiceSubmissionPreCheckSuccess = false;
            draft.createInvoiceSubmissionPreCheckCurrentStep = 0;
            draft.createInvoiceSubmissionPreCheckOptions = [];
            draft.isCreateInvoiceSubmissionPreCheckModalOpen = false;
            break;
        }
        case invoiceSubmissionActionTypes.OPEN_CREATE_INVOICE_SUBMISSION_PRE_CHECK_MODAL: {
            draft.isCreateInvoiceSubmissionPreCheckModalOpen = true;
            break;
        }
        case invoiceSubmissionActionTypes.CLOSE_CREATE_INVOICE_SUBMISSION_PRE_CHECK_MODAL: {
            draft.isCreateInvoiceSubmissionPreCheckModalOpen = false;
            break;
        }
        case invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_PRE_CHECK_SET_CURRENT_STEP: {
            const {currentStep} = action.payload;
            draft.createInvoiceSubmissionPreCheckCurrentStep = currentStep;
            break;
        }
        case invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_PRE_CHECK_SET_OPTIONS: {
            draft.createInvoiceSubmissionPreCheckOptions = action.payload;
            break;
        }
        case invoiceSubmissionActionTypes.SET_IS_CREATE_INVOICE_SUBMISSION_PRE_CHECK_SUCCESS: {
            const {isSuccess} = action.payload;
            draft.isCreateInvoiceSubmissionPreCheckSuccess = isSuccess;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_CREATE_INVOICE_SUBMISSION_STATUS: {
            const {
                createInvoiceSubmissionState,
                isMemberIdFound,
                errors,
            } = action.payload;
            draft.createInvoiceSubmissionState = createInvoiceSubmissionState;
            draft.isMemberIdFound = isMemberIdFound;
            draft.errors = errors;
            break;
        }

        case invoiceSubmissionActionTypes.STORE_PRICING_INFO: {
            const {pricingDTO} = payload;
            draft.pricingInfo = pricingDTO.flat;
            break;
        }
        case invoiceSubmissionActionTypes.SET_PRICING_DATA_READY: {
            const {isPricingDataReady} = payload;
            draft.isPricingDataReady = isPricingDataReady;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_SUBMIT_POPUP_STATUS: {
            const {submitPopUpStatus} = payload;
            draft.submitPopUpStatus = submitPopUpStatus;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_FOLLOW_UP_INVOICE_SUBMISSION_MAIN_SERVICES: {
            const {mainServices} = payload;
            draft.followUpInvoiceSubmissionMainServices = mainServices;
            break;
        }
        case invoiceSubmissionActionTypes.SET_DOWNLOAD_NOTIFICATION_MODAL_STATE: {
            const {isDownloadNotificationModalOpen} = payload;
            draft.isDownloadNotificationModalOpen = isDownloadNotificationModalOpen;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_SERVICE_FIXED_PRICES: {
            const {pricePeriodDTOs} = payload;
            draft.serviceFixesPrices = pricePeriodDTOs;
            break;
        }
        case invoiceSubmissionActionTypes.STORE_OPEN_CAR_MAX_PRICES: {
            const {openCarMaxPrice} = payload;
            draft.openCarMaxPrice = openCarMaxPrice;
            break;
        }

        default:
        // no-op
    }
}, initialState);

export default invoiceSubmissionReducer;
