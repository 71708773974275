import {take, put, select} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {invoiceSubmissionViews} from '../constants/invoiceSubmissionViews';

const changingViewState = function* () {
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.CHANGE_INVOICE_SUBMISSION_VIEW_STATE);
        const {invoiceSubmissionViewState} = payload;

        if (invoiceSubmissionViewState === invoiceSubmissionViews.FORM) {
            const {invoiceSubmissionId} = payload;
            const {invoiceSubmissions} = yield select(state => state.invoiceSubmission);
            const invoiceSubmission = invoiceSubmissions[invoiceSubmissionId];

            yield put({
                type: invoiceSubmissionActionTypes.FETCH_SERVICE_FIXED_PRICE_FOR_SELECTED_DATE,
                payload: {
                    invoiceSubmissionId,
                    serviceEndDateTime: invoiceSubmission?.assignment.serviceEndDateTime,
                },
            });
        }

        yield put({type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_VIEW_STATE, payload});
    }
};

export default changingViewState;
