import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {produce} from 'immer';
import {withTranslations} from '@computerrock/formation-i18n';
import {convertNumberToCurrency, encodeFloatStandard, decodeFloatStandard} from '../utils/conversion';
import {Input, ErrorMessage, TextArea, Select} from '../../ui-components';
import './AdditionalServicesSection.scss';
import {isElementInArray, isNotEmptyObject} from '../../utils/utils';
import {BillingTypes} from '../constants/billingTypes';
import {
    additionalForMainServices,
    AdditionalServices,
} from '../constants/additionalServices';
import {NumberValidator, PriceValidator} from '../../utils/validation';
import SelectOption from '../../ui-components/select/SelectOption';
import {isFollowUpInvoiceSubmission} from '../utils/subCases';
import vehicleTonnages from '../../ella-dispo-entity-types/vehicle/VehicleTonnages';
import {trailer} from '../constants/trailer';
import config from '../../config';

class AdditionalServicesSection extends React.Component {
    static propTypes = {
        onDataChange: PropTypes.func.isRequired,
        isInvoiceSubmissionClosed: PropTypes.bool.isRequired,
        errors: PropTypes.any,
        forwardRef: PropTypes.any,
        isInvoiceSubmissionRequestedSubService: PropTypes.bool.isRequired,
        invoiceSubmission: PropTypes.object.isRequired,
        invoiceSubmissionMainServices: PropTypes.object,
        translate: PropTypes.func,
        serviceFixesPrices: PropTypes.array,
    };

    static defaultProps = {
        errors: {},
        forwardRef: '',
        invoiceSubmissionMainServices: {},
        translate: null,
        serviceFixesPrices: [],
    };

    state = {
        focus: false,
    };

    componentDidUpdate(prevProps) {
        const areServiceFixesPricesChanged = this.props.serviceFixesPrices.find(service => (
            service.serviceType === AdditionalServices.FUEL_SUBSIDY
        ))?.value !== prevProps.serviceFixesPrices.find(service => (
            service.serviceType === AdditionalServices.FUEL_SUBSIDY
        ))?.value
            || this.props.serviceFixesPrices.find(service => (
                service.serviceType === AdditionalServices.INFLATION_SUBSIDY
            ))?.value !== prevProps.serviceFixesPrices.find(service => (
                service.serviceType === AdditionalServices.INFLATION_SUBSIDY
            ))?.value
            || this.props.serviceFixesPrices.find(service => (
                service.serviceType === AdditionalServices.SURCHARGE_TRAILER
            ))?.value !== prevProps.serviceFixesPrices.find(service => (
                service.serviceType === AdditionalServices.SURCHARGE_TRAILER
            ))?.value;

        if (areServiceFixesPricesChanged) {
            const newSelectedServices = this.props.invoiceSubmission.additionalServices.map(service => {
                if ([
                    AdditionalServices.FUEL_SUBSIDY,
                    AdditionalServices.INFLATION_SUBSIDY,
                    AdditionalServices.SURCHARGE_TRAILER,
                ].includes(service.type)) {
                    return {
                        ...service,
                        amount: this.props.serviceFixesPrices.find(serviceFixesPrice => (
                            serviceFixesPrice.serviceType === service.type
                        ))?.value || 0,
                    };
                }
                return service;
            });
            this.props.onDataChange({
                additionalServices: newSelectedServices,
            });
        }
    }

    gettingIdsFromErrors = () => {
        const {errors} = this.props;
        if (errors) {
            const regex = /(additionalServices\[)(\d)(].amount)/;
            return Object.keys(errors).filter(err => {
                return regex.test(err);
            })
                .map(err => {
                    return parseInt(err.replace(regex, '$2'), 10);
                });
        }
        return [];
    };

    getAdditionalServiceTypes = services => {
        return services.map(({type}) => {
            return type;
        });
    };

    shouldRemoveAdditionalPersonal = (option, billingType) => {
        return billingType === BillingTypes.FIXED_PRICE && option === AdditionalServices.ADDITIONAL_STAFF;
    };

    onServiceChange = name => {
        const {invoiceSubmission, serviceFixesPrices} = this.props;
        const newServiceName = name;
        const newSelectedServices = [...invoiceSubmission.additionalServices];

        const price = [
            AdditionalServices.FUEL_SUBSIDY,
            AdditionalServices.INFLATION_SUBSIDY,
            AdditionalServices.SURCHARGE_TRAILER,
        ].includes(newServiceName)
            ? serviceFixesPrices?.find(fixedPrice => fixedPrice.serviceType === newServiceName)?.value || '-'
            : '';

        newSelectedServices.push({
            type: newServiceName,
            amount: price,
            newlyAdded: true,
        });

        this.props.onDataChange({
            additionalServices: newSelectedServices,
        });
    };

    onServiceDelete = serviceName => {
        const {isInvoiceSubmissionClosed, invoiceSubmission} = this.props;
        let newSelectedServices = [...invoiceSubmission.additionalServices];
        if (isInvoiceSubmissionClosed) {
            newSelectedServices = newSelectedServices.filter(option => {
                return !(option.type === serviceName && option.newlyAdded);
            });
        } else {
            const selectedServicesArray = this.getAdditionalServiceTypes(newSelectedServices);
            const index = selectedServicesArray.indexOf(serviceName);
            newSelectedServices.splice(index, 1);
        }
        this.props.onDataChange({
            additionalServices: newSelectedServices,
            ...(!newSelectedServices.length && {additionalServicesComment: ''}),
        });
    };

    onChangePrice = ({service, value}) => {
        const {isInvoiceSubmissionClosed, invoiceSubmission} = this.props;
        let newSelectedServices = [...invoiceSubmission.additionalServices];
        let oldServices = [];
        const isInt = service === AdditionalServices.ADDITIONAL_STAFF;
        const inputValidation = isInt ? NumberValidator.validate : PriceValidator.validate;
        const charLimit = isInt ? 2 : 8;
        if (isInvoiceSubmissionClosed) {
            oldServices = newSelectedServices.filter(option => {
                return !option.newlyAdded;
            });
            newSelectedServices = newSelectedServices.filter(option => {
                return option.newlyAdded;
            });
        }
        const selectedServicesArray = this.getAdditionalServiceTypes(newSelectedServices);
        const index = selectedServicesArray.indexOf(service);
        newSelectedServices[index] = produce(newSelectedServices[index], draft => {
            draft.amount = (inputValidation(value) && value.length <= charLimit)
                ? value
                : newSelectedServices[index].amount;
            if (service === AdditionalServices.ADDITIONAL_STAFF) {
                draft.amount = (inputValidation(value) && value.length <= charLimit)
                    ? value
                    : newSelectedServices[index].amount;
            }
        });
        this.props.onDataChange({
            additionalServices: [
                ...oldServices,
                ...newSelectedServices,
            ],
        });
    };

    onPriceFocus = () => {
        this.setState({focus: true});
    };

    onPriceBlur = () => {
        const selectedServices = [...this.props.invoiceSubmission.additionalServices];
        selectedServices.forEach(service => {
            produce(service, draft => {
                draft.amount = encodeFloatStandard(service.amount);
            });
        });
        this.setState({
            focus: false,
        }, () => {
            this.props.onDataChange({
                additionalServices: selectedServices,
            });
        });
    };

    shouldRemoveInflationSubsidy(option, inflationSubsidyAvailable) {
        return !inflationSubsidyAvailable && option === AdditionalServices.INFLATION_SUBSIDY;
    }

    shouldRemoveSurchargeTonnageRoadsideAssistance(option) {
        const {invoiceSubmission} = this.props;
        return option === AdditionalServices.SURCHARGE_TONNAGE_ROADSIDE_ASSISTANCE
                && (moment(invoiceSubmission.startDate).isBefore(config.INTERSECTION_DATE)
                || (invoiceSubmission.vehicleWeight !== vehicleTonnages.WEIGHT_VALUE_3
                        && invoiceSubmission.vehicleWeight !== vehicleTonnages.WEIGHT_VALUE_5)
                    || invoiceSubmission.billingType !== BillingTypes.TIME);
    }

    shouldRemoveSurchargeTrailer(option) {
        const {invoiceSubmission} = this.props;
        return option === AdditionalServices.SURCHARGE_TRAILER
            && (invoiceSubmission.hasTrailer === trailer.HAS_TRAILER || invoiceSubmission.assignment?.hasTrailer);
    }

    render() {
        const {invoiceSubmission, isInvoiceSubmissionClosed, errors, forwardRef, translate} = this.props;
        const {isInvoiceSubmissionRequestedSubService, invoiceSubmissionMainServices} = this.props;
        const isCaseFollowUpInvoiceSubmission = isFollowUpInvoiceSubmission(invoiceSubmission.invoiceSubmissionId);
        const selectedMainServices = isCaseFollowUpInvoiceSubmission
            ? (invoiceSubmissionMainServices || {}) : invoiceSubmission.decodedMainServices;
        const {focus} = this.state;
        const inflationSubsidyAvailable = moment(invoiceSubmission.startDate).isSameOrAfter(moment('01-01-2023', 'DD-MM-yyyy')) && invoiceSubmission.inflationSubsidyAllowed;
        const options = (isInvoiceSubmissionRequestedSubService
            ? Object.keys(AdditionalServices).filter(service => {
                return !isElementInArray(service, [
                    AdditionalServices.SURCHARGE_TONNAGE_TOWING,
                    AdditionalServices.SURCHARGE_TONNAGE_PICK_UP,
                    AdditionalServices.SURCHARGE_TONNAGE_SALVAGE,
                ]);
            })
            : [...new Set(Object.keys(selectedMainServices).reduce((acc, mainService) => {
                return [
                    ...acc,
                    ...additionalForMainServices[mainService],
                ];
            }, []))]
                .filter(option => {
                    return !this.shouldRemoveAdditionalPersonal(option, invoiceSubmission.billingType)
                        && !this.shouldRemoveInflationSubsidy(option, inflationSubsidyAvailable)
                        && !this.shouldRemoveSurchargeTonnageRoadsideAssistance(option)
                        && !this.shouldRemoveSurchargeTrailer(option);
                })
        // From 1.1.2025. zero subsidies are allowed: in case they are zero, do not offer them
        ).filter(option => {
            if ([AdditionalServices.INFLATION_SUBSIDY,
                AdditionalServices.FUEL_SUBSIDY,
                AdditionalServices.SURCHARGE_TRAILER].includes(option)) {
                const service = this.props.serviceFixesPrices?.find(service => service.serviceType === option);
                if (service && !service?.value) return false;
            }
            return true;
        });
        this.gettingIdsFromErrors();
        const selectedServicesArray = this.getAdditionalServiceTypes(invoiceSubmission.additionalServices);
        const newSelectedServices = invoiceSubmission.additionalServices.filter(option => {
            return option.newlyAdded;
        });
        const newSelectedServicesArray = this.getAdditionalServiceTypes(newSelectedServices);
        const availableOptionsForLastSelect = options.filter(
            option => {
                return isInvoiceSubmissionClosed
                    ? !newSelectedServicesArray.includes(option) : !selectedServicesArray.includes(option);
            },
        );
        const additionalServicesWrapperClass = classnames('additional-service-wrapper', {
            'additional-service-wrapper--error': errors.additionalServices,
        });

        // hide select box when no options to select are available
        const hasOptionsToSelect = availableOptionsForLastSelect.length > 0;
        const heightParameters = {
            numberOfOptions: selectedServicesArray.length,
            marginSize: 42,
            inputSize: 60,
        };
        const idsOfEmptyPrices = this.gettingIdsFromErrors();

        return (
            <div ref={forwardRef} className="ace-additional-services__ref">
                <div className="ace-grid__row ace-bottom-margin--lg">
                    <div className="col-xs--12">
                        <span className="ace-copy-m">{translate(`additional_services_section.title.additional_services`)}</span>
                    </div>
                </div>
                <div className={additionalServicesWrapperClass} key={2}>
                    {selectedServicesArray.map((service, idx) => {
                        const id = idx + 1;
                        const showPrice = service !== AdditionalServices.ADDITIONAL_STAFF;
                        let priceValue = invoiceSubmission.additionalServices[idx].amount;
                        const {newlyAdded} = invoiceSubmission.additionalServices[idx];
                        if (invoiceSubmission.additionalServices[idx].amount !== '' && showPrice) {
                            priceValue = convertNumberToCurrency(parseFloat(encodeFloatStandard(priceValue)));
                        } else if (!showPrice) {
                            priceValue = parseInt(invoiceSubmission.additionalServices[idx].amount, 10) || '';
                        }
                        if (focus) {
                            priceValue = id !== parseInt(document.activeElement.id, 10)
                                ? priceValue : decodeFloatStandard(invoiceSubmission.additionalServices[idx].amount);
                        }
                        const showComment = idx === 0;
                        const hasError = idsOfEmptyPrices.indexOf(idx) !== -1;
                        const priceClass = classnames('ace-additional-services__input-wrapper', {
                            'ace-additional-services__input-wrapper--errors': hasError,
                        });
                        const maxLength = showPrice ? '8' : '2';
                        const additionalServiceWrapperClass = classnames('ace-additional-services__bottom-margin', {
                            'ace-additional-services__bottom-margin--disable-element': isInvoiceSubmissionClosed && !newlyAdded,
                        });
                        return (
                            <div
                                key={idx}
                                className={classnames('ace-grid__row ace-additional-services__service-wrapper', additionalServiceWrapperClass)}
                            >
                                <div className="col-xs--4">
                                    <Input
                                        value={translate(`global.additional_services.${service.toLowerCase()}`)}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className={classnames('col-xs--2', priceClass)}>
                                    {service !== AdditionalServices.SURCHARGE_TONNAGE_ROADSIDE_ASSISTANCE && (
                                        <>
                                            <Input
                                                type="text"
                                                id={id}
                                                onFocus={this.onPriceFocus}
                                                onBlur={this.onPriceBlur}
                                                onChange={this.onChangePrice}
                                                service={service}
                                                value={priceValue}
                                                error={hasError
                                                    ? translate(`global.validation_messages.${errors[`additionalServices[${idx}].amount`]}`)
                                                    : ''}
                                                label={
                                                    service === AdditionalServices.ADDITIONAL_STAFF
                                                        ? translate('additional_services_section.input_label.people')
                                                        : translate('additional_services_section.input_label.net_amount')
                                                }
                                                additionalAttributes={{maxLength}}
                                                isDisabled={service === AdditionalServices.FUEL_SUBSIDY
                                                    || service === AdditionalServices.INFLATION_SUBSIDY
                                                    || service === AdditionalServices.SURCHARGE_TRAILER}
                                            />
                                            {showPrice && (
                                                <div className="ace-additional-services__euro-sign-wrapper">
                                                    <span className="ace-additional-services__euro-sign">€</span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="col-xs--1">
                                    {(!isInvoiceSubmissionClosed
                                        || invoiceSubmission.additionalServices[idx].newlyAdded) && (
                                        <div className="ace-additional-services__cancel-field">
                                            <p
                                                className="ace-link-m"
                                                onClick={() => {
                                                    this.onServiceDelete(service);
                                                }}
                                            >
                                                {translate('additional_services_section.field_title.remove')}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="col-xs--1" />
                                <div className="col-xs--4">
                                    {showComment && (
                                        <TextArea
                                            className="ace-additional-services__comment"
                                            name="additional-service-comment"
                                            value={invoiceSubmission.additionalServicesComment}
                                            label={translate('additional_services_section.text_area_label.additional_service_comment')}
                                            onChange={value => {
                                                this.props.onDataChange({additionalServicesComment: value});
                                            }}
                                            isAdditionalServices={true}
                                            heightParameters={heightParameters}
                                            isOptional={!selectedServicesArray.includes(AdditionalServices.SURCHARGE_TONNAGE_ROADSIDE_ASSISTANCE)} // eslint-disable-line max-len
                                            error={errors.additionalServicesComment
                                                && translate(`global.validation_messages.${errors.additionalServicesComment}`)} // eslint-disable-line max-len
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    <div className="ace-grid__row ace-bottom-margin--md">
                        {(hasOptionsToSelect || !isNotEmptyObject(selectedMainServices)) && (
                            <div className="col-xs--4">
                                <Select
                                    placeholder={translate('global.select_placeholder.please_select')}
                                    onChange={this.onServiceChange}
                                    isDisabled={!availableOptionsForLastSelect.length > 0}
                                >
                                    {availableOptionsForLastSelect.map(option => {
                                        return (
                                            <SelectOption
                                                key={option}
                                                optionValue={option}
                                                optionText={translate(`global.additional_services.${option.toLowerCase()}`)}
                                            />
                                        );
                                    })}
                                </Select>
                            </div>
                        )}
                    </div>
                </div>
                <div className="ace-grid__row">
                    <div className="col-xs--12">
                        {errors.additionalServices && (
                            <ErrorMessage error={translate(`global.validation_messages.${errors.additionalServices}`)} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        serviceFixesPrices: state.invoiceSubmission.serviceFixesPrices,
    };
};

export default connect(mapStateToProps, null)(withTranslations(AdditionalServicesSection));
