import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {AceDatePicker, ErrorMessage, Icon, TimeInput} from '../../ui-components';

const GENERAL_DATA_DATE_FORMAT = ['dd.MM.yyyy', 'dd.MM.yy'];

const InvoiceSubmissionDatesTimes = ({invoiceSubmission, errors, isDisabled, onDataChange, onDateBlur}) => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translateDatesTimes = createTranslateShorthand('invoice_submission_dates_times');
    const startEndTimeWrapper = classnames('ace-error__wrapper', {
        'ace-error__wrapper--errors': errors['assignment.serviceStartDateTime']
            || errors['assignment.serviceEndDateTime'],
    });

    return (
        <>
            <div className="ace-bottom-margin--sm">
                <div className={classnames('ace-grid__row', startEndTimeWrapper)}>
                    <div className="col-xs--3">
                        <AceDatePicker
                            id="start-date"
                            name="startDate"
                            dateFormat={GENERAL_DATA_DATE_FORMAT}
                            popperPlacement="bottom-end"
                            selected={new Date(invoiceSubmission.startDate)}
                            onChange={value => onDataChange('startDate', value)}
                            errors={errors}
                            label={translateDatesTimes('date_picker_label.start_date')}
                            isDisabled={isDisabled}
                        />
                    </div>
                    <div className="col-xs--2">
                        <TimeInput
                            name="startTime"
                            label={translateDatesTimes('input_label.start_time')}
                            labelFor="startTime"
                            time={invoiceSubmission.startTime}
                            onChange={onDataChange}
                            errors={errors}
                            isDisabled={isDisabled}
                        />
                    </div>
                    <div className="col-xs--3">
                        <AceDatePicker
                            id="end-date"
                            name="endDate"
                            dateFormat={GENERAL_DATA_DATE_FORMAT}
                            popperPlacement="bottom-end"
                            selected={new Date(invoiceSubmission.endDate)}
                            onChange={value => onDataChange('endDate', value)}
                            errors={errors}
                            label={translateDatesTimes('date_picker_label.end_date')}
                            isDisabled={isDisabled}
                            onBlur={onDateBlur}
                        />
                    </div>
                    <div className="col-xs--2">
                        <TimeInput
                            name="endTime"
                            label={translateDatesTimes('input_label.end_time')}
                            labelFor="endTime"
                            time={invoiceSubmission.endTime}
                            onChange={onDataChange}
                            errors={errors}
                            isDisabled={isDisabled}
                        />
                    </div>
                </div>
                {(errors['assignment.serviceStartDateTime'] || errors['assignment.serviceEndDateTime']) && (
                    <div className="ace-grid__row">
                        <div className="col-xs--12">
                            <ErrorMessage
                                error={
                                (errors['assignment.serviceStartDateTime']
                                && translate(`global.validation_messages.${errors['assignment.serviceStartDateTime']}`))
                                || (errors['assignment.serviceEndDateTime']
                                        && translate(`global.validation_messages.${errors['assignment.serviceEndDateTime']}`))
                            }
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="ace-grid__row ace-bottom-margin--md">
                <div className="col-xs--5 col-xl--4">
                    <div className="ace-main-service__text-wrapper ace-main-service__text-wrapper--margin">
                        <span className="ace-main-service__icon-wrapper">
                            <Icon dimension="xl" iconName="clock" />
                        </span>
                        <div className="ace-main-service__tariff-wrapper">
                            <span className="ace-copy-m">{translateDatesTimes('field_title.use_duration')}:&nbsp;</span>
                            <span className="ace-copy-m">{invoiceSubmission.invoiceSubmissionDuration}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

InvoiceSubmissionDatesTimes.propTypes = {
    invoiceSubmission: PropTypes.object.isRequired,
    errors: PropTypes.object,
    onDataChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    onDateBlur: PropTypes.func,
};

InvoiceSubmissionDatesTimes.defaultProps = {
    errors: {},
    isDisabled: false,
    onDateBlur: null,
};

export default InvoiceSubmissionDatesTimes;
