import {eafMainServices} from '../../ella-dispo-entity-types';

export const AdditionalServices = {
    ADDITIONAL_STAFF: 'ADDITIONAL_STAFF',
    TAXI_7: 'TAXI_7',
    RENTAL_CAR: 'RENTAL_CAR',
    RENTAL_CAR_DELIVERY_FEE: 'RENTAL_CAR_DELIVERY_FEE',
    RENTAL_CAR_EMERGENCY_SERVICE: 'RENTAL_CAR_EMERGENCY_SERVICE',
    VEHICLE_RETURN_TRANSPORT_ENTREPRENEURS: 'VEHICLE_RETURN_TRANSPORT_ENTREPRENEURS',
    VEHICLE_UNDERFLOOR_HALL: 'VEHICLE_UNDERFLOOR_HALL',
    VEHICLE_UNDERCOVER_OUTDOOR_AREA: 'VEHICLE_UNDERCOVER_OUTDOOR_AREA',
    TOLL_FEE_ABROAD: 'TOLL_FEE_ABROAD',
    TOLLS_DOMESTIC: 'TOLLS_DOMESTIC',
    ASSISTANCE_FEES_IN_GERMANY_GDV_VSHT_VSN: 'ASSISTANCE_FEES_IN_GERMANY_GDV_VSHT_VSN',
    OTHER_COSTS: 'OTHER_COSTS',
    SURCHARGE_TONNAGE_TOWING: 'SURCHARGE_TONNAGE_TOWING',
    SURCHARGE_TONNAGE_PICK_UP: 'SURCHARGE_TONNAGE_PICK_UP',
    SURCHARGE_TONNAGE_SALVAGE: 'SURCHARGE_TONNAGE_SALVAGE',
    SURCHARGE_TONNAGE_ROADSIDE_ASSISTANCE: 'SURCHARGE_TONNAGE_ROADSIDE_ASSISTANCE',
    SURCHARGE_TRAILER: 'SURCHARGE_TRAILER',
    FUEL_SUBSIDY: 'FUEL_SUBSIDY',
    INFLATION_SUBSIDY: 'INFLATION_SUBSIDY',
};

export const additionalForMainServices = {
    [eafMainServices.ROADSIDE_ASSISTANCE]: [
        AdditionalServices.ADDITIONAL_STAFF,
        AdditionalServices.TOLL_FEE_ABROAD,
        AdditionalServices.TOLLS_DOMESTIC,
        AdditionalServices.ASSISTANCE_FEES_IN_GERMANY_GDV_VSHT_VSN,
        AdditionalServices.OTHER_COSTS,
        AdditionalServices.FUEL_SUBSIDY,
        AdditionalServices.INFLATION_SUBSIDY,
        AdditionalServices.SURCHARGE_TONNAGE_ROADSIDE_ASSISTANCE,
        AdditionalServices.SURCHARGE_TRAILER,
    ],
    [eafMainServices.OPENING]: [
        AdditionalServices.ADDITIONAL_STAFF,
        AdditionalServices.TOLL_FEE_ABROAD,
        AdditionalServices.TOLLS_DOMESTIC,
        AdditionalServices.ASSISTANCE_FEES_IN_GERMANY_GDV_VSHT_VSN,
        AdditionalServices.OTHER_COSTS,
        AdditionalServices.FUEL_SUBSIDY,
        AdditionalServices.INFLATION_SUBSIDY,
        AdditionalServices.SURCHARGE_TRAILER,
    ],
    [eafMainServices.JUMP_START]: [
        AdditionalServices.ADDITIONAL_STAFF,
        AdditionalServices.TOLL_FEE_ABROAD,
        AdditionalServices.TOLLS_DOMESTIC,
        AdditionalServices.ASSISTANCE_FEES_IN_GERMANY_GDV_VSHT_VSN,
        AdditionalServices.OTHER_COSTS,
        AdditionalServices.FUEL_SUBSIDY,
        AdditionalServices.INFLATION_SUBSIDY,
        AdditionalServices.SURCHARGE_TRAILER,
    ],
    [eafMainServices.RECOVERY]: [
        AdditionalServices.ADDITIONAL_STAFF,
        AdditionalServices.TAXI_7,
        AdditionalServices.RENTAL_CAR,
        AdditionalServices.RENTAL_CAR_DELIVERY_FEE,
        AdditionalServices.RENTAL_CAR_EMERGENCY_SERVICE,
        AdditionalServices.VEHICLE_RETURN_TRANSPORT_ENTREPRENEURS,
        AdditionalServices.VEHICLE_UNDERFLOOR_HALL,
        AdditionalServices.VEHICLE_UNDERCOVER_OUTDOOR_AREA,
        AdditionalServices.TOLL_FEE_ABROAD,
        AdditionalServices.TOLLS_DOMESTIC,
        AdditionalServices.ASSISTANCE_FEES_IN_GERMANY_GDV_VSHT_VSN,
        AdditionalServices.OTHER_COSTS,
        AdditionalServices.FUEL_SUBSIDY,
        AdditionalServices.INFLATION_SUBSIDY,
        AdditionalServices.SURCHARGE_TRAILER,
    ],
    [eafMainServices.TOWING]: [
        AdditionalServices.ADDITIONAL_STAFF,
        AdditionalServices.TAXI_7,
        AdditionalServices.RENTAL_CAR,
        AdditionalServices.RENTAL_CAR_DELIVERY_FEE,
        AdditionalServices.RENTAL_CAR_EMERGENCY_SERVICE,
        AdditionalServices.VEHICLE_RETURN_TRANSPORT_ENTREPRENEURS,
        AdditionalServices.VEHICLE_UNDERFLOOR_HALL,
        AdditionalServices.VEHICLE_UNDERCOVER_OUTDOOR_AREA,
        AdditionalServices.TOLL_FEE_ABROAD,
        AdditionalServices.TOLLS_DOMESTIC,
        AdditionalServices.ASSISTANCE_FEES_IN_GERMANY_GDV_VSHT_VSN,
        AdditionalServices.OTHER_COSTS,
        AdditionalServices.FUEL_SUBSIDY,
        AdditionalServices.INFLATION_SUBSIDY,
        AdditionalServices.SURCHARGE_TRAILER,
    ],
    [eafMainServices.PICKUP]: [
        AdditionalServices.ADDITIONAL_STAFF,
        AdditionalServices.TAXI_7,
        AdditionalServices.RENTAL_CAR,
        AdditionalServices.RENTAL_CAR_DELIVERY_FEE,
        AdditionalServices.RENTAL_CAR_EMERGENCY_SERVICE,
        AdditionalServices.VEHICLE_RETURN_TRANSPORT_ENTREPRENEURS,
        AdditionalServices.VEHICLE_UNDERFLOOR_HALL,
        AdditionalServices.VEHICLE_UNDERCOVER_OUTDOOR_AREA,
        AdditionalServices.TOLL_FEE_ABROAD,
        AdditionalServices.TOLLS_DOMESTIC,
        AdditionalServices.ASSISTANCE_FEES_IN_GERMANY_GDV_VSHT_VSN,
        AdditionalServices.OTHER_COSTS,
        AdditionalServices.FUEL_SUBSIDY,
        AdditionalServices.INFLATION_SUBSIDY,
        AdditionalServices.SURCHARGE_TRAILER,
    ],
    [eafMainServices.EMPTY_TRIP]: [
        AdditionalServices.ADDITIONAL_STAFF,
        AdditionalServices.TOLL_FEE_ABROAD,
        AdditionalServices.TOLLS_DOMESTIC,
        AdditionalServices.ASSISTANCE_FEES_IN_GERMANY_GDV_VSHT_VSN,
        AdditionalServices.OTHER_COSTS,
        AdditionalServices.FUEL_SUBSIDY,
        AdditionalServices.INFLATION_SUBSIDY,
        AdditionalServices.SURCHARGE_TRAILER,
    ],
};

