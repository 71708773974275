import {EmptyTripReasons} from '../constants/emptyTrip';
import {eafMainServices} from '../../ella-dispo-entity-types';
import {eafMainServiceIcons} from '../constants/invoiceSubmissionMainServices';

const SURCHARGE_MAPPING = {
    [eafMainServices.RECOVERY]: {
        main: 'Einsatz',
        surcharge: 'Bergungszuschlag',
    },
    [eafMainServices.PICKUP]: {
        main: 'Anfahrt',
        surcharge: 'Pick-up',
    },
};

export const getMainServiceNameForType = ({type, isPrincipalService}, translate) => {
    if (type) {
        const mapping = SURCHARGE_MAPPING[type];
        if (mapping) {
            return isPrincipalService ? mapping.main : mapping.surcharge;
        }
        return translate(`global.eaf_main_service.${type.toLowerCase()}`);
    }
    return '';
};

export const isMainServiceSelected = (selectedServices, service) => {
    return !!selectedServices && Object.keys(selectedServices).indexOf(service) !== -1;
};

export const getServiceInfo = (serviceType, isEmptyTrip, emptyTripReason, translate) => {
    const serviceIcon = !isEmptyTrip
        ? (serviceType ? eafMainServiceIcons[serviceType] : '')
        : (emptyTripReason ? EmptyTripReasons[emptyTripReason].icon : '');
    const serviceName = (!isEmptyTrip && serviceType) || (isEmptyTrip && emptyTripReason)
        ? !isEmptyTrip
            ? translate(`global.eaf_main_service.${serviceType.toLowerCase()}`)
            : translate(`global.empty_trip_reasons.${emptyTripReason.toLowerCase()}`)
        : null;

    return {
        serviceName,
        serviceIcon,
    };
};

export const decodeMainServices = services => {
    return services
        .map(({type}) => type)
        .reduce((acc, service) => {
            return {
                ...acc,
                [service]: eafMainServices[service],
            };
        }, {});
};
