import {put, fork, take, select} from 'redux-saga/effects';
import {push} from '@computerrock/formation-router';
import moment from 'moment';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import {encodeFloatStandard} from '../utils/conversion';
import {formatingRecoveryDateTime} from '../utils/formatingRecoveryDateTime';
import fetchRequest from '../../application/sagas/fetchRequest';
import {parseErrorMessages} from '../../utils/validationMessages';
import {InvoiceSubmissionDataStates} from '../constants/invoiceSubmissionDataStates';
import routePaths from '../../routePaths';
import {accident} from '../constants/accident';
import {trailer} from '../constants/trailer';
import {isMainServiceSelected} from '../utils/mainServices';
import {eafMainServices} from '../../ella-dispo-entity-types';
import {invoiceSubmissionViews} from '../constants/invoiceSubmissionViews';
import getPricingInfoFlow from '../../pricing/sagas/getPricingInfoFlow';
import {decodeAdditionalServices} from '../utils/additionalServices';
import {apsPricingTypes} from '../constants/apsPricingTypes';

const createUpdateData = invoiceSubmission => {
    const serviceStartDateTime = invoiceSubmission.startDate ? moment(invoiceSubmission.startDate)
        .hours(invoiceSubmission.startTime.hours || 0)
        .minutes(invoiceSubmission.startTime.minutes || 0)
        .seconds(invoiceSubmission.startTime.seconds || 0)
        .utc()
        .format() : null;
    const serviceEndDateTime = invoiceSubmission.endDate ? moment(invoiceSubmission.endDate)
        .hours(invoiceSubmission.endTime.hours || 0)
        .minutes(invoiceSubmission.endTime.minutes || 0)
        .seconds(invoiceSubmission.endTime.seconds || 0)
        .utc()
        .format() : null;

    const damageAddress = {
        street: invoiceSubmission.damageAddressStreet,
        postCode: invoiceSubmission.damagePostCode,
        city: invoiceSubmission.damageCity,
        country: invoiceSubmission.damageCountry,
    };
    const damageLocation = {
        longitude: invoiceSubmission.damageLocation?.lng || '',
        latitude: invoiceSubmission.damageLocation?.lat || '',
    };
    const pickupAddress = {
        street: invoiceSubmission.pickUpAddressStreet,
        postCode: invoiceSubmission.pickUpAddressPostCode,
        city: invoiceSubmission.pickUpAddressCity,
        country: invoiceSubmission.pickUpAddressCountry,
    };
    const towingAddress = {
        street: invoiceSubmission.towingAddressStreet,
        postCode: invoiceSubmission.towingAddressPostCode || '',
        city: invoiceSubmission.towingAddressCity || '',
        country: invoiceSubmission.towingAddressCountry || '',
    };
    const towingLocation = {
        longitude: invoiceSubmission.towingLocation?.lng || '',
        latitude: invoiceSubmission.towingLocation?.lat || '',
    };
    const towing = {
        address: towingAddress,
        destinationName: invoiceSubmission.towingDestinationName,
        destinationType: invoiceSubmission.towingDestinationType || null,
        location: towingLocation,
        isInsideVpPolygon: invoiceSubmission.isTowingLocationInsideVpPolygon,
    };
    const pickup = {
        address: pickupAddress,
        destinationName: invoiceSubmission.pickUpDestinationName,
        destinationType: invoiceSubmission.pickUpDestinationType || null,
        distance: invoiceSubmission.pickUpDistance,
    };

    const damage = {
        isInsideVpPolygon: invoiceSubmission.isInvoiceSubmissionInsideVpPolygon,
        address: damageAddress,
        location: damageLocation,
    };

    const isRecoverySelected = isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.RECOVERY);
    const assignment = {
        billingType: invoiceSubmission.billingType,
        isAccident: invoiceSubmission.isAccident === accident.IS_ACCIDENT,
        vehicleHasTrailer: invoiceSubmission.hasTrailer === trailer.HAS_TRAILER,
        journeyContinuation: invoiceSubmission.journeyContinuation,
        serviceStartDateTime,
        serviceEndDateTime,
        startOfRecoveryDateTime: isRecoverySelected ? formatingRecoveryDateTime(invoiceSubmission.startOfRecovery,
            invoiceSubmission.recoveryStart) : null,
        endOfRecoveryDateTime: isRecoverySelected ? formatingRecoveryDateTime(invoiceSubmission.endOfRecovery,
            invoiceSubmission.recoveryEnd) : null,
        damage,
        pickup,
        towing,
        emptyTripReason: invoiceSubmission.emptyTripReason || null,
    };
    return {
        externalDocumentNumber: invoiceSubmission.externalDocumentNo || null,
        mainServices: Object.keys(invoiceSubmission.decodedMainServices).map(service => {
            return service;
        }).filter(service => !!service),
        mainServicesComment: invoiceSubmission.mainServicesComment
            ? invoiceSubmission.mainServicesComment?.replace(/\n/g, ' ') : '',
        additionalServices: decodeAdditionalServices(invoiceSubmission.additionalServices)
            .map(({type, amount}) => {
                return {
                    type,
                    amount: parseFloat(encodeFloatStandard(amount)),
                };
            }),
        additionalServicesComment: invoiceSubmission.additionalServicesComment
            ? invoiceSubmission.additionalServicesComment?.replace(/\n/g, ' ') : '',
        assignment,
        linkAttachments: {
            attachments: invoiceSubmission.attachments,
        },
    };
};

const updateInvoiceSubmissionFlow = function* updateInvoiceSubmissionFlow() {
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION);
        const {invoiceSubmissionId, data, isNoValidation} = payload;
        yield put({type: errorActionTypes.DELETE_ERRORS});
        const files = yield select(state => state.invoiceSubmissionFilesReducer.files);

        const attachments = Object.values(files).map(file => {
            return {
                id: file.id,
                name: file.fileName,
                isSignature: file.isSignature,
            };
        });

        const updateData = createUpdateData({...data, attachments});

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.UPDATING},
        });
        const {serviceManager} = yield select(state => state.application);
        const eafService = serviceManager.loadService('ellaAssignmentFlowService');
        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST,
            eafService.updateInvoiceSubmission,
            {invoiceSubmissionId, data: updateData, isNoValidation},
        );
        const resultAction = yield take([
            invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_REQUEST_FAILED,
        ]);

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.IDLE},
        });

        if (!resultAction.error) {
            if (isNoValidation) {
                yield put(push(routePaths.OPEN_INVOICE_SUBMISSIONS));
                continue;
            }
            const {response: invoiceSubmission} = resultAction.payload;
            const apmService = serviceManager.loadService('partnerManagementService');

            yield fork(getPricingInfoFlow, {
                payload: {
                    contractPartnerId: invoiceSubmission.assignment?.contractPartnerId,
                    serviceEndDateTime: invoiceSubmission.assignment?.serviceEndDateTime || moment().format(),
                    type: apsPricingTypes.CONTRACT_PARTNER_FIXED_PRICE,
                },
            });

            yield fork(
                fetchRequest,
                invoiceSubmissionActionTypes.GET_CONTRACT_PARTNER_INFO_REQUEST,
                apmService.getACEPartner,
                {acePartnerId: invoiceSubmission.assignment.contractPartnerId},
            );

            const resultContractPartnerAction = yield take([
                invoiceSubmissionActionTypes.GET_CONTRACT_PARTNER_INFO_REQUEST_SUCCEEDED,
                invoiceSubmissionActionTypes.GET_CONTRACT_PARTNER_INFO_REQUEST_FAILED,
            ]);

            if (!resultContractPartnerAction.error) {
                const invoiceSubmissionViewState = invoiceSubmissionViews.SUMMARY;
                const {acePartnerDTO: contractPartnerDTO} = resultContractPartnerAction.payload.response;
                yield put({
                    type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_VIEW_STATE,
                    payload: {invoiceSubmissionViewState},
                });
                yield put({
                    type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION,
                    payload: {
                        invoiceSubmissionDTO: {
                            ...invoiceSubmission,
                            assignment: {
                                ...invoiceSubmission.assignment,
                                contractPartnerInfo: contractPartnerDTO,
                            },
                        },
                    },
                });
                yield put({
                    type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
                    payload: {state: InvoiceSubmissionDataStates.IDLE},
                });
            }
            continue;
        }
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(resultAction.payload),
                },
            },
        });
    }
};

export default updateInvoiceSubmissionFlow;
