import {renderRoutes} from '@computerrock/formation-router';
import routePaths from './routePaths';
import * as userManagementLoaderSagas from './user-management/userManagementLoaderSagas';
import * as driverAssignmentsLoaderSagas from './driver-assignments/driverAssignmentsLoaderSagas';
import * as vpContactLoaderSagas from './contract-partner/contractPartnerLoaderSagas';
import * as invoiceSubmissionLoaderSagas from './invoice-submission/invoiceSubmissionLoaderSagas';
import * as supportFormLoaderSagas from './support/supportFormLoaderSagas';
import * as messagesLoaderSagas from './messages/messagesLoaderSagas';
import * as documentDownloadLoaderSagas from './document-download/documentDownloadLoaderSagas';
import App from './application/App';
import PageNotFoundView from './application/PageNotFoundView';
import BlankPageView from './application/view-elements/BlankPageView';
import SelectAppView from './application/SelectAppView';
import AccessNotAllowedView from './application/view-elements/AccessNotAllowedView';
import ManageUsersView from './user-management/ManageUsersView';
import DriverStatusView from './driver-assignments/DriversStatusView';
import SubmittedInvoiceSubmissionsView from './invoice-submission/SubmittedInvoiceSubmissionsView';
import OpenInvoiceSubmissionsView from './invoice-submission/OpenInvoiceSubmissionsView';
import SupportView from './support/SupportView';
import {UserRoles} from './ella-dispo-entity-types';
import OpenDriverAssignmentsView from './driver-assignments/OpenDriverAssignmentsView';
import AssignedDriverAssignmentsView from './driver-assignments/AssignedDriverAssignmentsView';
import DriverAssignmentView from './driver-assignments/DriverAssignmentView';
import DriverAssignmentsArchiveView from './driver-assignments/DriverAssignmentsArchiveView';
import TestView2 from './TestView';
import AccountingArchiveView from './invoice-submission/InvoiceSubmissionArchiveView';
import InvoiceSubmissionView from './invoice-submission/InvoiceSubmissionView';
import keycloakAuth from './keycloakAuth';
import DocumentDownloadView from './document-download/DocumentDownloadView';
import Maintenance from './application/Maintenance';
import AccessDeniedScreen from './application/AccessDeniedScreen';


/**
 * Location object definition (react-router, history)
 *
 * @typedef {Object} Location
 * @property {string} key - key is available for all by HashHistory
 * @property {string} pathname - path part of route
 * @property {string} search - search query part of route
 * @property {string} hash - hash (#) part of route
 * @property {Object} state - user defined state for the route
 */

/**
 * ConnectedRoute object definition (react-router-config, connected-react-router, redux-saga)
 *
 * @typedef {Object} ConnectedRoute
 * @property {string} path - any valid URL path that path-to-regexp understands.
 * @property {?Object} component - React component
 * @property {?function} render - render prop function
 * @property {?Location} location - for matching against different location than one in history
 * @property {?boolean} exact - when true, will only match if the path matches the location.pathname exactly
 * @property {?boolean} string - when true, a path that has a trailing slash will only match a location.pathname
 *                      with a trailing slash
 * @property {?boolean} sensitive - when true, will match if the path is case sensitive.
 * @property {?Array<ConnectedRoute>} routes - sub-routes
 * @property {?Array<Array<[saga, Object]>>} locationChangeSideEffects - Redux sagas (side-effects) to be run
 *                                            with payload object
 */

/**
 * Static route configuration
 *
 * @returns {Array<ConnectedRoute>}
 */
const getRoutes = () => [{
    component: App,
    locationChangeSideEffects: [],
    routes: [
        window.DYNAMIC_ENV.IS_MAINTENANCE_MODE_ON
        && window.location.hostname !== 'ella-bd.ace-ms.de'
        && window.location.hostname !== 'ella-bd.dev.ace-ecs.computerrock.com'
        && window.location.hostname !== 'ella-bd.int.ace-ecs.computerrock.com'
        && window.location.hostname !== 'ella-bd.pp.ecs-nonprod.de'
        && window.location.hostname !== 'ella-bd.pp2.ecs-nonprod.de'
            ? {
                path: '*',
                component: Maintenance,
            }
            : {
                path: routePaths.OIDC_AUTHORIZE,
                exact: true,
                component: BlankPageView,
                locationChangeSideEffects: [
                    [keycloakAuth.authorize],
                ],
            },
        {
            path: routePaths.OIDC_AUTHENTICATE,
            exact: true,
            component: BlankPageView,
            locationChangeSideEffects: [
                [keycloakAuth.authenticate],
                [userManagementLoaderSagas.registerPushNotifications],
            ],
        },
        {
            path: routePaths.SYSTEM_ACCESS_DENIED,
            exact: true,
            component: AccessDeniedScreen,
            locationChangeSideEffects: [
                [keycloakAuth.loadAuthSession],
            ],
        },
        {
            component: ({route}) => renderRoutes(route.routes),
            locationChangeSideEffects: [
                [keycloakAuth.loadAuthSession],
                [userManagementLoaderSagas.loadUserProfile], // user profile api calls
            ],
            routes: [
                {
                    path: routePaths.TEST,
                    exact: true,
                    component: TestView2,
                    locationChangeSideEffects: [],
                },
                {
                    path: routePaths.ROOT,
                    exact: true,
                    component: () => null,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.selectAppAccessControl],
                    ],
                },
                {
                    path: routePaths.SELECT_APP,
                    exact: true,
                    component: SelectAppView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl, {
                            allowedRoles: [UserRoles.ADMINISTRATOR, UserRoles.ACCOUNTANT, UserRoles.DISPATCHER],
                            redirectTo: routePaths.OIDC_AUTHORIZE,
                        }],
                    ],
                },
                {
                    path: routePaths.ACCESS_NOT_ALLOWED,
                    exact: true,
                    component: AccessNotAllowedView,
                },
                {
                    path: routePaths.MANAGE_USERS,
                    exact: true,
                    component: ManageUsersView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl, {
                            allowedRoles: [UserRoles.ADMINISTRATOR],
                            redirectTo: routePaths.SELECT_APP,
                        }],
                        [userManagementLoaderSagas.loadUsers],
                    ],
                },
                {
                    path: routePaths.DISPATCH_MANAGE_USERS,
                    exact: true,
                    component: ManageUsersView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl, {
                            allowedRoles: [UserRoles.ADMINISTRATOR],
                            redirectTo: routePaths.SELECT_APP,
                        }],
                        [userManagementLoaderSagas.loadUsers],
                    ],
                },
                {
                    path: routePaths.OPEN_INVOICE_SUBMISSIONS,
                    exact: true,
                    component: OpenInvoiceSubmissionsView,
                    locationChangeSideEffects: [
                        [
                            userManagementLoaderSagas.userRolesAccessControl, {
                                allowedRoles: [UserRoles.ACCOUNTANT, UserRoles.ADMINISTRATOR],
                                redirectTo: routePaths.SELECT_APP,
                            },
                        ],
                        [invoiceSubmissionLoaderSagas.loadOpenInvoiceSubmissions],
                        [messagesLoaderSagas.loadPartnersMessages],
                    ],
                },
                {
                    path: routePaths.SUBMITTED_INVOICE_SUBMISSIONS,
                    exact: true,
                    component: SubmittedInvoiceSubmissionsView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl,
                            {
                                allowedRoles: [UserRoles.ACCOUNTANT, UserRoles.ADMINISTRATOR],
                                redirectTo: routePaths.SELECT_APP,
                            }],
                        [invoiceSubmissionLoaderSagas.loadSubmittedInvoiceSubmissions],
                        [messagesLoaderSagas.loadPartnersMessages],
                    ],
                },
                {
                    path: routePaths.INVOICE_SUBMISSION,
                    exact: true,
                    component: InvoiceSubmissionView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl,
                            {
                                allowedRoles: [UserRoles.ACCOUNTANT, UserRoles.ADMINISTRATOR],
                                redirectTo: routePaths.SELECT_APP,
                            }],
                        [invoiceSubmissionLoaderSagas.loadInvoiceSubmission],
                        [invoiceSubmissionLoaderSagas.loadValidServiceFixedPrices],
                        [invoiceSubmissionLoaderSagas.loadOpenCarMaxPrice],
                    ],
                },
                {
                    path: routePaths.ARCHIVE,
                    exact: true,
                    component: AccountingArchiveView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl,
                            {
                                allowedRoles: [UserRoles.ACCOUNTANT, UserRoles.ADMINISTRATOR],
                                redirectTo: routePaths.SELECT_APP,
                            }],
                        [invoiceSubmissionLoaderSagas.loadArchiveInvoiceSubmissions],
                    ],
                },
                {
                    path: routePaths.DOCUMENTS,
                    exact: true,
                    component: DocumentDownloadView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl,
                            {
                                allowedRoles: [UserRoles.ADMINISTRATOR],
                                redirectTo: routePaths.SELECT_APP,
                            }],
                        [documentDownloadLoaderSagas.loadDocuments],
                    ],
                },
                {
                    path: routePaths.MANAGE_USERS,
                    exact: true,
                    component: ManageUsersView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl, {
                            allowedRoles: [UserRoles.ADMINISTRATOR, UserRoles.DISPATCHER],
                            redirectTo: routePaths.SELECT_APP,
                        }],
                        [userManagementLoaderSagas.loadUsers],
                    ],

                },
                {
                    path: routePaths.DISPATCH_MANAGE_USERS,
                    exact: true,
                    component: ManageUsersView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl, {
                            allowedRoles: [UserRoles.ADMINISTRATOR, UserRoles.DISPATCHER],
                            redirectTo: routePaths.SELECT_APP,
                        }],
                        [userManagementLoaderSagas.loadUsers],
                    ],
                },
                {
                    path: routePaths.DISPATCH_OPEN_DRIVER_ASSIGNMENTS,
                    exact: true,
                    component: OpenDriverAssignmentsView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl,
                            {
                                allowedRoles: [UserRoles.ADMINISTRATOR, UserRoles.DISPATCHER],
                                redirectTo: routePaths.SELECT_APP,
                            }],
                        [driverAssignmentsLoaderSagas.loadDispatchOpenDriverAssignments],
                    ],
                },
                {
                    path: routePaths.DISPATCH_ASSIGNED_DRIVER_ASSIGNMENTS,
                    exact: true,
                    component: AssignedDriverAssignmentsView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl,
                            {
                                allowedRoles: [UserRoles.ADMINISTRATOR, UserRoles.DISPATCHER],
                                redirectTo: routePaths.SELECT_APP,
                            }],
                        [driverAssignmentsLoaderSagas.loadDispatchAssignedDriverAssignments],
                    ],
                },
                {
                    path: routePaths.DISPATCH_ARCHIVE,
                    exact: true,
                    component: DriverAssignmentsArchiveView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl,
                            {
                                allowedRoles: [UserRoles.ADMINISTRATOR, UserRoles.DISPATCHER],
                                redirectTo: routePaths.SELECT_APP,
                            }],
                        [driverAssignmentsLoaderSagas.loadArchivedDriverAssignments],
                    ],
                },
                {
                    path: routePaths.DISPATCH_DRIVER_ASSIGNMENT,
                    exact: true,
                    component: DriverAssignmentView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl,
                            {
                                allowedRoles: [UserRoles.ADMINISTRATOR, UserRoles.DISPATCHER],
                                redirectTo: routePaths.SELECT_APP,
                            }],
                        [driverAssignmentsLoaderSagas.loadDriverAssignment],
                    ],
                },
                {
                    path: routePaths.DRIVER,
                    exact: true,
                    component: DriverStatusView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl,
                            {
                                allowedRoles: [UserRoles.ADMINISTRATOR, UserRoles.DISPATCHER],
                                redirectTo: routePaths.SELECT_APP,
                            }],
                        [vpContactLoaderSagas.getContractPartnerInfo],
                    ],
                },
                {
                    path: routePaths.SUPPORT,
                    exact: true,
                    component: SupportView,
                    locationChangeSideEffects: [
                        [userManagementLoaderSagas.userRolesAccessControl,
                            {
                                allowedRoles: [
                                    UserRoles.ACCOUNTANT,
                                    UserRoles.ADMINISTRATOR,
                                ],
                                redirectTo: routePaths.ACCESS_NOT_ALLOWED,
                            }],
                        [supportFormLoaderSagas.clearFilesData],
                    ],
                },
                {
                    path: '*',
                    component: PageNotFoundView,
                },


            ],
        },
    ],
}];

export default getRoutes;
