import {put, take, select, all, fork} from 'redux-saga/effects';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import * as errorActionTypes from '../application/errorActionTypes';
import * as userProfileSelectors from '../user-management/userManagementSelectors';
import * as userActionTypes from '../user-management/userManagementActionTypes';
import {parseErrorMessages} from '../utils/validationMessages';
import fetchRequest from '../application/sagas/fetchRequest';


export const getContractPartnerInfo = function* getContractPartnerInfo() {
    yield put({
        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
        payload: {contractPartnersDTO: []},
    });

    yield put({
        type: userActionTypes.STORE_USERS,
        payload: {userDTOs: []},
    });

    const userAccount = yield select(userProfileSelectors.getUserAccount);
    const vpIds = userAccount.partnerIds;
    let count = 0;
    const contractPartners = [];
    const {serviceManager} = yield select(state => state.application);
    const apmService = serviceManager.loadService('partnerManagementService');

    yield all(vpIds.map(id => fork(
        fetchRequest,
        contractPartnerActionTypes.GET_CONTRACT_PARTNER_REQUEST,
        apmService.getACEPartner, {acePartnerId: id},
    )));

    while (count < vpIds.length) {
        const contractPartnerAction = yield take([
            contractPartnerActionTypes.GET_CONTRACT_PARTNER_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.GET_CONTRACT_PARTNER_REQUEST_FAILED,
        ]);

        if (contractPartnerAction.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(contractPartnerAction.payload),
                    },
                },
            });
            count += 1;
            return;
        }

        const {acePartnerDTO: contractPartnerDTO} = contractPartnerAction.payload.response;
        contractPartners.push(contractPartnerDTO);
        count += 1;
    }

    yield put({
        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
        payload: {contractPartnersDTO: contractPartners},
    });
    const deumService = serviceManager.loadService('userManagementService');

    yield fork(fetchRequest, userActionTypes.FETCH_USERS_REQUEST, deumService.fetchUsers, {partnerIds: vpIds, roleFilter: 'DRIVER'});

    const resultAction = yield take([
        userActionTypes.FETCH_USERS_REQUEST_SUCCEEDED,
        userActionTypes.FETCH_USERS_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        let users = resultAction.payload.response.data;

        const DLService = serviceManager.loadService('driverLocatorService');
        yield fork(fetchRequest, userActionTypes.FETCH_USERS_LOCATION_REQUEST, DLService.getDriversLocations,
            {partnerIds: vpIds});
        const result = yield take([
            userActionTypes.FETCH_USERS_LOCATION_REQUEST_SUCCEEDED,
            userActionTypes.FETCH_USERS_LOCATION_REQUEST_FAILED,
        ]);

        if (result.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(result.payload),
                    },
                },
            });
            return;
        }
        // MAPPING DRIVERS WITH LOCATIONS
        const lc = result.payload.response.data;
        const locations = lc.reduce((locationMapping, lc) => {
            locationMapping[lc.driverId] = lc;
            return locationMapping;
        }, {});
        users = users.map(user => {
            return locations[user.userId] ? {
                ...user,
                location: {
                    latitude: locations[user.userId].latitude,
                    longitude: locations[user.userId].longitude,
                },
            } : user;
        });

        yield put({
            type: userActionTypes.STORE_USERS,
            payload: {userDTOs: users},
        });
        return;
    }
    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                error: {...parseErrorMessages(resultAction.payload)},
            },
        },
    });
};

