import {immerable} from 'immer';
import Entity from '../entity/Entity';
import Address from '../address/Address';
import TariffDetails from './TariffDetails';

/**
 * Member entity type definition
 *
 * @typedef {Object} Member
 * @property {?Address} address
 * @property {?string} membershipNumber
 * @property {?string} name
 * @property {?string} phoneNumber
 * @property {?string} email
 * @property {?string} type
 * @property {?TariffDetails} tariffDetails,
 */

/**
 * Member entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const memberEntityDTOMapping = {
    type: {
        dtoProperty: 'type',
        defaultValue: 'MEMBER',
    },
    name: {
        dtoProperty: 'name',
        defaultValue: '',
    },
    email: {
        dtoProperty: 'email',
        defaultValue: '',
    },
    phoneNumber: {
        dtoProperty: 'phoneNumber',
        defaultValue: '',
    },
    address: {
        dtoProperty: 'address',
        defaultValue: new Address(),
        entity: Address,
    },
    membershipNumber: {
        dtoProperty: 'membershipNumber',
        defaultValue: '',
    },
    tariffDetails: {
        dtoProperty: 'tariffDetails',
        defaultValue: null,
        entity: TariffDetails,
    },
};

/**
 * Member entity
 *
 * @class
 * @extends Entity
 * @type {Member}
 */
export default class Member extends Entity {
    [immerable] = true;

    static entityDTOMapping = memberEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
