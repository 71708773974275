import {take, put, select, fork} from 'redux-saga/effects';
import * as userSelectors from '../user-management/userManagementSelectors';
import * as errorActionTypes from '../application/errorActionTypes';
import * as driverAssignmentsSelectors from './driverAssignmentSelectors';
import getUsersFlow from '../user-management/sagas/getUsersFlow';
import * as driverAssignmentActions from './driverAssignmentActions';
import * as userManagementActions from '../user-management/userManagementActionTypes';
import {driverAssignmentsParams} from './constants/driverAssignmentMappings';
import {parseErrorMessages} from '../utils/validationMessages';
import {driverAssignmentViews} from './constants/driverAssignmentViews';
import {UsersPurpose} from '../user-management/constants/usersPurpose';
import fetchRequest from '../application/sagas/fetchRequest';
import {getUserAccount} from '../user-management/userManagementSelectors';
import {isStartDateBeforeEndDate} from '../utils/times';
import {dafStatuses} from '../ella-dispo-entity-types';

export const loadDispatchOpenDriverAssignments = function* () {
    const query = driverAssignmentsParams.DISPATCH_OPEN.query;
    const userAccount = yield select(userSelectors.getUserAccount);
    // clear search results if search is active
    const isSearchViewActive = yield select(driverAssignmentsSelectors.getIsSearchViewActive);
    if (isSearchViewActive) {
        yield put({type: driverAssignmentActions.CLEAR_DRIVER_ASSIGNMENTS_SEARCH_RESULTS});
    }
    const {serviceManager} = yield select(state => state.application);
    const dafService = serviceManager.loadService('driverAssignmentFlowService');

    yield put({
        type: driverAssignmentActions.SET_DRIVER_ASSIGNMENTS_STATE,
        payload: {isLoading: true},
    });
    yield put({type: driverAssignmentActions.CLEAR_DRIVER_ASSIGNMENT_DATA});
    yield fork(fetchRequest, driverAssignmentActions.GET_DRIVER_ASSIGNMENTS_REQUEST, dafService.getAssignments, {
        ...query,
        partnerIds: userAccount.partnerIds,
    });
    const resultAction = yield take([
        driverAssignmentActions.GET_DRIVER_ASSIGNMENTS_REQUEST_SUCCEEDED,
        driverAssignmentActions.GET_DRIVER_ASSIGNMENTS_REQUEST_FAILED,
    ]);
    if (!resultAction.error) {
        const {response} = resultAction.payload;
        yield put({
            type: driverAssignmentActions.STORE_OPEN_DRIVER_ASSIGNMENTS,
            payload: {
                driverAssignmentsDTO: response.results.data,
                totalCount: response.totalCount,
            },
        });
        yield put({
            type: driverAssignmentActions.SET_DRIVER_ASSIGNMENTS_STATE,
            payload: {isLoading: false},
        });
        return;
    }
    yield put({
        type: driverAssignmentActions.SET_DRIVER_ASSIGNMENTS_STATE,
        payload: {isLoading: false},
    });
    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(resultAction.payload),
            },
        },
    });
};

export const loadDispatchAssignedDriverAssignments = function* () {
    const query = driverAssignmentsParams.DISPATCH_ASSIGNED.query;
    const userAccount = yield select(userSelectors.getUserAccount);

    // clear search results if search is active
    const isSearchViewActive = yield select(driverAssignmentsSelectors.getIsSearchViewActive);
    if (isSearchViewActive) {
        yield put({type: driverAssignmentActions.CLEAR_DRIVER_ASSIGNMENTS_SEARCH_RESULTS});
    }

    const {serviceManager} = yield select(state => state.application);
    const dafService = serviceManager.loadService('driverAssignmentFlowService');

    yield put({
        type: driverAssignmentActions.SET_DRIVER_ASSIGNMENTS_STATE,
        payload: {isLoading: true},
    });
    yield put({type: driverAssignmentActions.CLEAR_DRIVER_ASSIGNMENT_DATA});

    yield fork(fetchRequest, driverAssignmentActions.GET_DRIVER_ASSIGNMENTS_REQUEST, dafService.getAssignments, {
        ...query,
        partnerIds: userAccount.partnerIds,
    });

    const resultAction = yield take([
        driverAssignmentActions.GET_DRIVER_ASSIGNMENTS_REQUEST_SUCCEEDED,
        driverAssignmentActions.GET_DRIVER_ASSIGNMENTS_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        const {response} = resultAction.payload;
        yield put({
            type: driverAssignmentActions.STORE_ASSIGNED_DRIVER_ASSIGNMENTS,
            payload: {driverAssignments: response.results},
        });
        yield put({
            type: driverAssignmentActions.SET_DRIVER_ASSIGNMENTS_STATE,
            payload: {isLoading: false},
        });
        return;
    }
    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(resultAction.payload),
            },
        },
    });
};

export const loadDriverAssignment = function* ({payload}) {
    const isSearchViewActive = yield select(driverAssignmentsSelectors.getIsSearchViewActive);
    if (isSearchViewActive) {
        yield put({type: driverAssignmentActions.CLEAR_DRIVER_ASSIGNMENTS_SEARCH_RESULTS});
    }
    yield put({type: errorActionTypes.DELETE_ERRORS});
    let {driverAssignmentId} = payload; // status also
    if (!driverAssignmentId) {
        const {match} = payload; // location also
        driverAssignmentId = match.params.driverAssignmentId;
        // status = location.status;
    }
    const {serviceManager} = yield select(state => state.application);
    const dafService = serviceManager.loadService('driverAssignmentFlowService');
    yield put({
        type: driverAssignmentActions.SET_DRIVER_ASSIGNMENTS_STATE,
        payload: {isLoading: true},
    });
    yield fork(fetchRequest, driverAssignmentActions.GET_DRIVER_ASSIGNMENT_REQUEST, dafService.getAssignment, {
        assignmentId: driverAssignmentId,
    });
    const actionResults = yield take([
        driverAssignmentActions.GET_DRIVER_ASSIGNMENT_REQUEST_SUCCEEDED,
        driverAssignmentActions.GET_DRIVER_ASSIGNMENT_REQUEST_FAILED,
    ]);

    if (!actionResults.error) {
        const driverAssignmentDTO = actionResults.payload.response.results;
        const driverAssignmentsViewState = driverAssignmentDTO.status === dafStatuses.BILLABLE
        || driverAssignmentDTO.status === dafStatuses.DECLINED
            ? driverAssignmentViews.DISPATCH_INACTIVE : driverAssignmentViews.DISPATCH_ACTIVE;
        yield put({
            type: driverAssignmentActions.STORE_DRIVER_ASSIGNMENT_VIEW_STATE,
            payload: {
                driverAssignmentsViewState,
            },
        });

        const apmService = serviceManager.loadService('partnerManagementService');

        yield fork(
            fetchRequest,
            driverAssignmentActions.GET_CONTRACT_PARTNER_INFO_REQUEST,
            apmService.getACEPartner,
            {acePartnerId: driverAssignmentDTO.contractPartner?.id},
        );

        const resultContractPartnerAction = yield take([
            driverAssignmentActions.GET_CONTRACT_PARTNER_INFO_REQUEST_SUCCEEDED,
            driverAssignmentActions.GET_CONTRACT_PARTNER_INFO_REQUEST_FAILED,
        ]);

        if (resultContractPartnerAction.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(resultContractPartnerAction.payload),
                    },
                },
            });
            return;
        }

        const {acePartnerDTO: contractPartnerDTO} = resultContractPartnerAction.payload.response;

        let vpDriver = null;
        if (driverAssignmentDTO.driverId) {
            const {serviceManager} = yield select(state => state.application);
            const deumService = serviceManager.loadService('userManagementService');
            yield fork(fetchRequest, userManagementActions.FETCH_USER_REQUEST, deumService.fetchUser,
                {userId: driverAssignmentDTO.driverId});
            const vpDriverResult = yield take([
                userManagementActions.FETCH_USER_REQUEST_SUCCEEDED,
                userManagementActions.FETCH_USER_REQUEST_FAILED,
            ]);
            if (!vpDriverResult.error) {
                vpDriver = vpDriverResult.payload.response;
            }
        }

        yield put({
            type: driverAssignmentActions.STORE_DRIVER_ASSIGNMENT,
            payload: {
                ...driverAssignmentDTO,
                vpDriver,
                contractPartner: contractPartnerDTO,
            },
        });


        // yield put({type: fileUploadActionTypes.STORE_DAMAGE_EVENT_FILES,
        //      payload: {files: driverAssignmentDTO.attachments}});
        yield fork(getUsersFlow, {roleFilter: 'DRIVER', usersPurpose: UsersPurpose.FOR_DRIVER_ASSIGNMENT});
        return;
    }
    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(actionResults.payload),
            },
        },
    });
};

export const loadArchivedDriverAssignments = function* (params) {
    // clear search results if search is active
    const isSearchViewActive = yield select(driverAssignmentsSelectors.getIsSearchViewActive);
    if (isSearchViewActive) {
        yield put({type: driverAssignmentActions.CLEAR_DRIVER_ASSIGNMENTS_SEARCH_RESULTS});
    }
    yield put({type: driverAssignmentActions.CLEAR_DRIVER_ASSIGNMENT_DATA});
    yield put({
        type: driverAssignmentActions.SET_DRIVER_ASSIGNMENTS_STATE,
        payload: {isLoading: true},
    });
    const {search} = params.payload.location;
    const {size} = params;
    const userAccount = yield select(getUserAccount);

    const queryObject = Object.fromEntries(new URLSearchParams(search));
    const {page, status, mainService, fromServiceStartDateTime} = queryObject;
    const {toServiceEndDateTime, driverId, sortBy, direction} = queryObject;
    const date = {
        fromServiceStartDateTime: fromServiceStartDateTime,
        toServiceEndDateTime: isStartDateBeforeEndDate(fromServiceStartDateTime, toServiceEndDateTime)
            ? toServiceEndDateTime : fromServiceStartDateTime,
    };
    const archiveQueries = {
        partnerIds: userAccount.partnerIds,
        size: size || 10,
        ...(page && {page}),
        ...(mainService && {mainService}),
        ...(sortBy ? {sortBy} : {sortBy: 'SERVICE_START_DATE'}),
        ...(direction ? {direction} : {direction: 'DESC'}),
        ...(date || {}),
        ...(status && status.length
            ? {status} : {status: driverAssignmentsParams.DISPATCH_ARCHIVED.query.status}),
        ...(driverId && {driverId}),
    };

    const {serviceManager} = yield select(state => state.application);
    const dafService = serviceManager.loadService('driverAssignmentFlowService');
    yield fork(fetchRequest, driverAssignmentActions.GET_DRIVER_ASSIGNMENTS_REQUEST, dafService.getAssignments, {
        ...archiveQueries,
    });

    const archiveResults = yield take([
        driverAssignmentActions.GET_DRIVER_ASSIGNMENTS_REQUEST_SUCCEEDED,
        driverAssignmentActions.GET_DRIVER_ASSIGNMENTS_REQUEST_FAILED,
    ]);

    if (!archiveResults.error) {
        const {response} = archiveResults.payload;
        yield put({
            type: driverAssignmentActions.STORE_ARCHIVED_DRIVER_ASSIGNMENTS,
            payload: {driverAssignments: response},
        });
        yield put({
            type: driverAssignmentActions.SET_DRIVER_ASSIGNMENTS_STATE,
            payload: {isLoading: false},
        });
        return;
    }

    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                errorDetail: archiveResults.payload.error_description,
            },
        },
    });
};

