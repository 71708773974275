import {take, put, fork, select} from 'redux-saga/effects';
import {parseErrorMessages} from '../../utils/validationMessages';
import * as userProfileSelectors from '../userManagementSelectors';
import * as actionTypes from '../userManagementActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import recordPersistenceStates from '../../utils/recordPersistenceStates';
import recordPersistenceOperations from '../../utils/recordPersistenceOperations';
import fetchRequest from '../../application/sagas/fetchRequest';

/**
 * Create user flow
 */
const createUserFlow = function* createUserFlow() {
    const defaultPersistenceState = {
        persistenceOperation: recordPersistenceOperations.NO_OPERATION,
        persistenceState: recordPersistenceStates.IDLE,
    };

    const {serviceManager} = yield select(state => state.application);
    const userManagementService = serviceManager.loadService('userManagementService');

    mainFlow: while (true) {
        yield take(actionTypes.START_CREATE_USER_FLOW);
        yield put({type: errorActionTypes.DELETE_ERRORS});
        yield put({
            type: actionTypes.SET_USER_PERSISTENCE_STATE,
            payload: {
                persistenceOperation: recordPersistenceOperations.CREATE,
                userId: 'reset',
            },
        });

        let submitChoiceResult = yield take([
            actionTypes.END_CREATE_USER_FLOW,
            actionTypes.SUBMIT_CREATE_USER_FORM,
        ]);

        while (true) {
            if (submitChoiceResult.type === actionTypes.END_CREATE_USER_FLOW) {
                yield put({type: actionTypes.SET_USER_PERSISTENCE_STATE, payload: defaultPersistenceState});
                continue mainFlow;
            }

            yield put({type: errorActionTypes.DELETE_ERRORS});

            yield put({
                type: actionTypes.SET_USER_PERSISTENCE_STATE,
                payload: {
                    persistenceState: recordPersistenceStates.PENDING,
                },
            });

            const userAccount = yield select(userProfileSelectors.getUserAccount);
            const {payload} = submitChoiceResult;
            const {email, password, firstName, userRoles, phoneNumber} = payload;

            yield fork(fetchRequest, actionTypes.CREATE_USER_REQUEST, userManagementService.createUser, {
                email,
                password,
                firstName,
                lastName: '',
                userRoles,
                phoneNumber,
            });
            const submitRequestResult = yield take([
                actionTypes.END_CREATE_USER_FLOW,
                actionTypes.CREATE_USER_REQUEST_SUCCEEDED,
                actionTypes.CREATE_USER_REQUEST_FAILED,
            ]);

            if (submitRequestResult.type === actionTypes.END_CREATE_USER_FLOW) {
                yield put({type: actionTypes.SET_USER_PERSISTENCE_STATE, payload: defaultPersistenceState});
                continue mainFlow;
            }

            if (submitRequestResult.type === actionTypes.CREATE_USER_REQUEST_SUCCEEDED) {
                yield fork(fetchRequest, actionTypes.FETCH_USERS_REQUEST, userManagementService.fetchUsers, {
                    partnerIds: userAccount.partnerIds,
                });
                const resultAction = yield take([
                    actionTypes.FETCH_USERS_REQUEST_SUCCEEDED,
                    actionTypes.FETCH_USERS_REQUEST_FAILED,
                ]);

                const {data: userDTOs = []} = resultAction.payload.response;
                const createdUser = userDTOs.find(userDTO => userDTO.email === email);
                yield put({
                    type: actionTypes.STORE_USER,
                    payload: {
                        userDTO: createdUser,
                    },
                });
                yield put({
                    type: actionTypes.SET_USER_PERSISTENCE_STATE,
                    payload: {
                        persistenceState: recordPersistenceStates.SUCCEEDED,
                        userId: createdUser.userId,
                    },
                });
                break;
            }

            const errorObject = submitRequestResult?.payload?.message
                ? JSON.parse(submitRequestResult.payload.message) : undefined;
            yield put({
                type: actionTypes.SET_USER_PERSISTENCE_STATE,
                payload: {
                    persistenceState: recordPersistenceStates.FAILED,
                },
            });
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages({
                            errors: errorObject?.constraintViolations?.map(error => ({
                                field_name: error.field,
                                error_code: error.errorCode.toLowerCase(),
                            })),
                        }),
                    },
                },
            });

            submitChoiceResult = yield take([
                actionTypes.END_CREATE_USER_FLOW,
                actionTypes.SUBMIT_CREATE_USER_FORM,
            ]);
        }

        yield take(actionTypes.END_CREATE_USER_FLOW);
        yield put({type: actionTypes.SET_USER_PERSISTENCE_STATE, payload: defaultPersistenceState});
    }
};

export default createUserFlow;
