import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '../../ui-components/autocomplete/Autocomplete';
import ListOption from '../../ui-components/list/ListOption';

const SearchAutocomplete = props => {
    const [listItems, setListItems] = useState([]);


    useEffect(() => {
        if (props.value) {
            const storeSuggestions = function (predictions, status) {
                if (!predictions) {
                    return;
                }
                let count = 0;
                const streets = [];

                predictions.forEach(prediction => {
                    if (count > 5) return;
                    streets.push({value: prediction.description, id: prediction.place_id});
                    count += 1;
                });

                setListItems(streets);
            };

            const config = {
                input: props.value,
                types: ['address'],
                componentRestrictions: {country: 'de'},
                fields: ['address_components'],
            };

            const autocomplete = new window.google.maps.places.AutocompleteService();
            autocomplete.getQueryPredictions(config, storeSuggestions);
        }
    }, [props.value]);


    return (
        <Autocomplete
            {...props}
        >
            {listItems.map((listItem, idx) => {
                return (
                    <ListOption
                        optionText={listItem.value}
                        optionValue={listItem.value}
                        key={`${listItem.id}-${idx}`}
                        className={props.listOptionClassName}
                    />
                );
            })
            }
        </Autocomplete>
    );
};

SearchAutocomplete.propTypes = {
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
    value: PropTypes.string,
    name: PropTypes.string,
    additionalAttributes: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    listOptionClassName: PropTypes.string,

};

SearchAutocomplete.defaultProps = {
    label: '',
    isDisabled: false,
    value: '',
    name: '',
    additionalAttributes: {},
    errors: {},
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    listOptionClassName: '',
};

export default SearchAutocomplete;
