import React, {Fragment} from 'react';
import qs from 'qs';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import {withTranslations} from '@computerrock/formation-i18n';
import AppLayout from '../application/AppLayoutView';
import * as driverAssignmentsSelectors from './driverAssignmentSelectors';
import * as userManagementSelectors from '../user-management/userManagementSelectors';
import DriverAssignmentArchiveRow from './view-elements/rows/DriverAssignmentArchiveRow';
import {TableBody, TableHeader, TablePanel, TableHeaderCell} from '../ui-components/table';
import DriverAssignmentSearchResultsView from './DriverAssignmentSearchResultsView';
import {SearchInput, AceDatePicker} from '../ui-components';
import {FilterDropdown, FilterSelect, FilterOrder, FilterOption} from '../ui-components/filters';
import {driverAssignmentsArchiveMainServices} from './constants/mainServiceTypes';
import {dafArchiveStatuses} from './constants/driverAssignmentStatuses';
import Pagination from '../application/view-elements/Pagination';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import config from '../config';
import withDriverAssignmentSearch from './view-elements/withDriverAssignmentSearch';
import routePaths from '../routePaths';
import {isStartDateBeforeEndDate} from '../utils/times';
import './DriverAssignmentsArchiveView.scss';


const sortingNames = {
    ASSIGNMENT_ID: 'ASSIGNMENT_ID',
    DATE: 'SERVICE_START_DATE',
};

const initialState = {
    page: 1,
    pageSize: config.DEFAULT_RECORDS_PER_PAGE,
    areFiltersCleared: false,
    mainService: [],
    status: [],
    filterFromDate: '',
    filterToDate: '',
    sortBy: '',
    direction: '',
    driverId: [],
};

class DriverAssignmentsArchiveView extends React.Component {
    static propTypes = {
        total: PropTypes.number.isRequired,
        driverAssignments: PropTypes.array.isRequired,
        driverAssignmentSearchResults: PropTypes.array.isRequired,
        isSearchPending: PropTypes.bool.isRequired,
        isSearchTermCleared: PropTypes.bool.isRequired,
        searchDriverAssignments: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired,
        searchTerm: PropTypes.string.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isSearchViewActive: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        translate: PropTypes.func,
    };

    static defaultProps = {
        translate: null,
    };

    constructor(props) {
        super(props);
        const queryObject = qs.parse(props.location.search, {ignoreQueryPrefix: true, comma: true});
        const filterFromDate = queryObject.fromServiceStartDateTime ? moment(queryObject.fromServiceStartDateTime) : '';
        const filterToDate = queryObject.toServiceEndDateTime ? moment(queryObject.toServiceEndDateTime) : '';
        this.state = {
            ...initialState,
            ...queryObject,
            status: queryObject.status ? queryObject.status.split(',') : [],
            mainService: queryObject.mainService ? queryObject.mainService.split(',') : [],
            page: queryObject.page ? +queryObject.page : 1,
            driverId: queryObject.driverId ? [queryObject.driverId] : [],
            filterFromDate,
            filterToDate: isStartDateBeforeEndDate(filterFromDate, filterToDate) ? filterToDate : filterFromDate,
        };
        this.debounceApiCall = debounce(this.fetchArchiveData, 1000);
    }

    makeQueryObject = () => {
        const {page, mainService, status, sortBy, direction, driverId} = this.state;
        const {filterFromDate, filterToDate} = this.state;
        const fromServiceStartDateTime = filterFromDate ? filterFromDate.startOf('day').toISOString() : null;
        const toServiceEndDateTime = filterToDate ? filterToDate.endOf('day').toISOString() : null;
        return {
            ...(page && {page: page}),
            ...(mainService.length && {mainService}),
            ...(status.length && {status}),
            ...(fromServiceStartDateTime && {fromServiceStartDateTime}),
            ...(toServiceEndDateTime && {toServiceEndDateTime}),
            ...(driverId.length && {driverId}),
            ...(sortBy && {sortBy}),
            ...(direction && {direction}),
        };
    };

    fetchArchiveData = () => {
        const {history, location} = this.props;
        const filterObject = this.makeQueryObject();
        const query = qs.stringify(filterObject, {arrayFormat: 'comma'});

        history.push({
            pathname: location.pathname,
            search: query,
            query: filterObject,
        });
    };

    onFiltersChange = (filterName, filterValues) => {
        this.setState({
            [filterName]: filterValues,
            page: 1,
        }, this.debounceApiCall());
    };

    onFiltersChangeNew = (filterName, value) => {
        let shouldAddValue = true;
        let values = [];
        values = this.state[filterName].filter(val => {
            if (value === val) {
                shouldAddValue = false;
            }
            return value !== val;
        });
        this.setState({
            [filterName]: shouldAddValue ? [...values, value] : values,
            page: 1,
        }, this.debounceApiCall());
    };

    onDriverFilterChange = (filterName, value) => {
        this.setState(state => {
            return {
                [filterName]: state[filterName] !== value ? value : '',
                page: 1,
            };
        }, this.debounceApiCall());
    };

    onStartDateFilterChange = date => {
        const {filterToDate} = this.state;
        const startDate = date ? moment(date) : null;

        this.setState({
            filterFromDate: startDate,
            filterToDate: isStartDateBeforeEndDate(startDate, filterToDate) ? filterToDate : startDate,
            page: 1,
        }, this.debounceApiCall());
    };

    onEndDateFilterChange = date => {
        this.setState({
            filterToDate: date ? moment(date) : null,
            page: 1,
        }, this.debounceApiCall());
    };

    onFilterDateDelete = dateRange => {
        this.setState({
            [dateRange]: null,
            page: 1,
        }, this.debounceApiCall());
    };

    onSortChange = (sortName, sortValue) => {
        this.setState({
            sortBy: sortName,
            direction: sortValue,
        }, () => {
            this.fetchArchiveData();
        });
    };

    onPreviousClick = e => {
        e.preventDefault();
        if (!this.hasPrev()) return;

        this.setState(prevState => {
            const prevPage = prevState.page - 1;
            return {
                page: prevPage,
            };
        }, () => {
            this.fetchArchiveData();
        });
    };

    goToPage = (e, pageNumber) => {
        e.preventDefault();
        this.setState({
            page: pageNumber,
        }, () => {
            this.fetchArchiveData();
        });
    };

    onNextClick = e => {
        e.preventDefault();
        if (!this.hasNext()) return;

        this.setState(prevState => {
            const prevPage = prevState.page + 1;
            return {
                page: prevPage,
            };
        }, () => {
            this.fetchArchiveData();
        });
    };

    hasPrev = () => {
        return this.state.page > 1;
    };

    hasNext = () => {
        const {total} = this.props;
        const {page, pageSize} = this.state;

        return page * pageSize < total;
    };

    calculateTotalPagesNumber = () => {
        const {total} = this.props;
        const {pageSize} = this.state;
        const totalPages = total / pageSize;
        if (totalPages === 0) {
            return 1;
        }
        if (total % pageSize) {
            return Math.ceil(totalPages);
        }
        return totalPages;
    };

    render() {
        const {searchTerm, isSearchPending, isSearchTermCleared, isSearchViewActive} = this.props;
        const {searchDriverAssignments, clearSearchResults, isLoading, translate} = this.props;
        const {driverAssignments, driverAssignmentSearchResults /* drivers */} = this.props;
        const {page, sortBy, direction} = this.state;
        const {filterFromDate, filterToDate, mainService, status, driverId} = this.state;
        const rootPath = routePaths.DISPATCH_ARCHIVE;
        const totalPages = this.calculateTotalPagesNumber();
        const queryObject = this.makeQueryObject();
        const links = {
            firstPage: `${rootPath}?${qs.stringify({...queryObject, page: 1}, {arrayFormat: 'comma'})}`,
            lastPage: `${rootPath}?${qs.stringify({...queryObject, page: totalPages}, {arrayFormat: 'comma'})}`,
            prevPage: `${rootPath}?${qs.stringify({...queryObject, page: page === 1 ? 1 : page - 1}, {arrayFormat: 'comma'})}`,
            nextPage: `${rootPath}?${qs.stringify({...queryObject, page: page === totalPages ? totalPages : page + 1}, {arrayFormat: 'comma'})}`,
        };

        const driverOptions = driverAssignments.reduce((acc, curr) => {
            return {
                ...acc,
                [curr.driverId]: {
                    type: curr.driverId,
                    text: curr.driverName,
                },
            };
        }, {});

        return (
            <AppLayout>
                <div className="ace-c-driver-assignment-archive-view">
                    <div className="ace-c-driver-assignment-archive-view__header ace-grid__row">
                        <div className="col-start-xs--3 col-xs--8 col-start-md--4 col-md--6">
                            <SearchInput
                                hasSearchResults={isSearchViewActive
                                && driverAssignmentSearchResults.length === 0}
                                placeholder={translate('driver_assignments_archive_view.search_input_placeholder.search_term')}
                                label={translate('driver_assignments_archive_view.search_input_label.search_term')}
                                isSearchTermCleared={isSearchTermCleared}
                                doSearching={searchDriverAssignments}
                                clearSearchResults={clearSearchResults}
                                additionalAttributes={{maxLength: '50'}}
                                isDispatchSearch
                            />
                        </div>
                    </div>
                    {!isSearchViewActive ? (
                        <Fragment>
                            <TablePanel>
                                <TableHeader className="ace-c-driver-assignment-archive-view__table-header ace-grid__row">
                                    <TableHeaderCell className="col-xs--3">
                                        <FilterDropdown
                                            label={translate('driver_assignments_archive_view.filter_dropdown_label.status')}
                                            isActive={!!status.length}
                                        >
                                            <FilterSelect
                                                onChange={this.onFiltersChangeNew}
                                                filterName="status"
                                            >
                                                {
                                                    Object.values(dafArchiveStatuses).map(status => {
                                                        const isChecked = !!this.state.status
                                                            .find(element => element === status);
                                                        return (
                                                            <FilterOption
                                                                key={status}
                                                                value={status}
                                                                isChecked={isChecked}
                                                            >
                                                                {translate(`global.daf_status_text.${status.toLowerCase()}`)}
                                                            </FilterOption>
                                                        );
                                                    })
                                                }
                                            </FilterSelect>
                                        </FilterDropdown>
                                    </TableHeaderCell>
                                    <TableHeaderCell className="col-xs--3">
                                        <FilterDropdown
                                            label={translate('driver_assignments_archive_view.filter_dropdown_label.service')}
                                            isActive={!!mainService.length}
                                        >
                                            <FilterSelect
                                                onChange={this.onFiltersChangeNew}
                                                filterName="mainService"
                                            >
                                                {
                                                    Object.values(driverAssignmentsArchiveMainServices)
                                                        .map(mainService => {
                                                            const isChecked = !!this.state.mainService
                                                                .find(element => element === mainService);
                                                            return (
                                                                <FilterOption
                                                                    key={mainService}
                                                                    value={mainService}
                                                                    isChecked={isChecked}
                                                                >
                                                                    { // eslint-disable-next-line
                                                                        translate(`global.daf_main_services.${mainService.toLowerCase()}`)
                                                                    }
                                                                </FilterOption>
                                                            );
                                                        })
                                                }
                                            </FilterSelect>
                                        </FilterDropdown>
                                    </TableHeaderCell>
                                    <TableHeaderCell className="col-sm--2">
                                        <FilterDropdown
                                            label={translate('driver_assignments_archive_view.filter_dropdown_label.case_number')}
                                            isActive={sortBy === sortingNames.ASSIGNMENT_ID}
                                        >
                                            <FilterOrder
                                                onSortChange={this.onSortChange}
                                                name={sortingNames.ASSIGNMENT_ID}
                                                sortDirection={sortBy === sortingNames.ASSIGNMENT_ID ? direction : ''}
                                            />
                                        </FilterDropdown>
                                    </TableHeaderCell>
                                    <TableHeaderCell className="col-sm--2">
                                        <FilterDropdown
                                            label={translate('driver_assignments_archive_view.filter_dropdown_label.date')}
                                            isActive={sortBy === sortingNames.DATE
                                            || !!filterFromDate || !!filterToDate}
                                        >
                                            <FilterOrder
                                                onSortChange={this.onSortChange}
                                                name={sortingNames.DATE}
                                                sortDirection={sortBy === sortingNames.DATE ? direction : ''}
                                            />
                                            <AceDatePicker
                                                selected={filterFromDate
                                                    ? filterFromDate.toDate() : null}
                                                onChange={this.onStartDateFilterChange}
                                                name="date-from"
                                                isButtonInput={true}
                                                buttonInputLabel={translate('driver_assignments_archive_view.date_picker.from')}
                                                dateFormat="dd.M.yyyy"
                                                placeholderText={translate('driver_assignments_archive_view.date_picker.start_date')}
                                                filterName="filterFromDate"
                                                dateFilterDelete={this.onFilterDateDelete}
                                            />
                                            <AceDatePicker
                                                selected={filterToDate
                                                    ? filterToDate.toDate() : null}
                                                onChange={this.onEndDateFilterChange}
                                                name="date-to"
                                                isButtonInput={true}
                                                buttonInputLabel={translate('driver_assignments_archive_view.date_picker.to')}
                                                dateFormat="dd.M.yyyy"
                                                placeholderText={translate('driver_assignments_archive_view.date_picker.end_date')}
                                                filterName="filterToDate"
                                                dateFilterDelete={this.onFilterDateDelete}
                                                minDate={filterFromDate ? filterFromDate.toDate() : null}
                                            />
                                        </FilterDropdown>
                                    </TableHeaderCell>
                                    <TableHeaderCell className="col-sm--2">
                                        <FilterDropdown
                                            label="Fahrer"
                                            isActive={!!driverId.length}
                                        >
                                            <FilterSelect
                                                onChange={this.onDriverFilterChange}
                                                filterName="driverId"
                                            >
                                                {
                                                    Object.values(driverOptions).map(driver => {
                                                        const type = driver.type;
                                                        const isChecked = this.state.driverId === type;

                                                        return (
                                                            <FilterOption key={type} value={type} isChecked={isChecked}>
                                                                {driver.text}
                                                            </FilterOption>
                                                        );
                                                    })
                                                }
                                            </FilterSelect>
                                        </FilterDropdown>
                                    </TableHeaderCell>
                                </TableHeader>
                                {!isLoading ? (driverAssignments.length ? (
                                    <Fragment>
                                        <TableBody className="ace-c-driver-assignment-archive-view__table-body" hasRows={!!driverAssignments.length}>
                                            {driverAssignments.map(driverAssignment => {
                                                return (
                                                    <DriverAssignmentArchiveRow
                                                        key={driverAssignment.assignmentId}
                                                        driverAssignment={driverAssignment}
                                                    />
                                                );
                                            })}
                                        </TableBody>
                                        <Pagination
                                            onPreviousClick={this.onPreviousClick}
                                            onNextClick={this.onNextClick}
                                            goToPage={this.goToPage}
                                            totalPages={totalPages}
                                            currentPage={page}
                                            links={links}
                                        />
                                    </Fragment>
                                ) : (
                                    <ScreenMessage messageParagraphs={[
                                        translate('global.screen_message.no_order'),
                                        translate('global.screen_message.change_filter_criteria'),
                                    ]}
                                    />
                                )) : (
                                    <ScreenMessage
                                        messageParagraphs={[translate('global.screen_message.loading')]}
                                        isLoading
                                    />
                                )}
                            </TablePanel>
                        </Fragment>
                    ) : (
                        <DriverAssignmentSearchResultsView
                            searchResults={driverAssignmentSearchResults}
                            isSearchPending={isSearchPending}
                            searchTerm={searchTerm}
                        />
                    )}
                </div>
            </AppLayout>
        );
    }
}

const mapStateToProps = state => {
    const getDriverAssignments = driverAssignmentsSelectors.createArchivedDriverAssignmentsSelector();
    const getDrivers = userManagementSelectors.createCurrentUsersSelector();
    return {
        total: state.driverAssignments.totalCount,
        driverAssignments: getDriverAssignments(state),
        drivers: getDrivers(state),
        isLoading: state.driverAssignments.isLoading,
    };
};

export default withRouter(withDriverAssignmentSearch(
    connect(mapStateToProps, null)(withTranslations(DriverAssignmentsArchiveView)),
));
