export const journeyContinuationInitialState = {
    recoveryStart: {hours: '', minutes: ''},
    recoveryEnd: {hours: '', minutes: ''},
    recoveryDuration: '-',
    isAccident: 'NO_ACCIDENT',
    hasTrailer: 'NO_TRAILER',
    towingDestinationType: '',
    towingDestinationName: '',
    towingDestinationAddress: '',
    towingAddressStreet: '',
    towingAddressCity: '',
    towingAddressPostCode: '',
    towingAddressCountry: '',
    towingLocation: null,
    isTowingLocationInsideVpPolygon: false,
    pickUpDestinationType: '',
    pickUpDestinationName: '',
    pickUpDestinationAddress: '',
    pickUpAddressStreet: '',
    pickUpAddressCity: '',
    pickUpAddressPostCode: '',
    pickUpAddressCountry: '',
    pickUpDistance: '',
    additionalServices: [],
    additionalServicesComment: '',
    emptyTripReason: '',
};

export const recoveryInitialState = {
    recoveryStart: {hours: '', minutes: ''},
    recoveryEnd: {hours: '', minutes: ''},
    recoveryDuration: '-',
};

export const towingInitialState = {
    towingDestinationType: '',
    towingDestinationName: '',
    towingDestinationAddress: '',
    towingLocation: null,
    isTowingLocationInsideVpPolygon: false,
    towingAddressStreet: '',
    towingAddressCity: '',
    towingAddressPostCode: '',
    towingAddressCountry: '',
};

export const pickupInitialState = {
    pickUpDestinationType: '',
    pickUpDestinationName: '',
    pickUpDestinationAddress: '',
    pickUpAddressStreet: '',
    pickUpAddressCity: '',
    pickUpAddressPostCode: '',
    pickUpAddressCountry: '',
    pickUpDistance: '',
};
