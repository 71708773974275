import {fork, put, select, take} from 'redux-saga/effects';
import moment from 'moment';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import getPricingInfoFlow from '../../pricing/sagas/getPricingInfoFlow';
import {apsPricingTypes} from '../constants/apsPricingTypes';

const getValidFixedPrices = function* getValidFixedPrices() {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.FETCH_SERVICE_FIXED_PRICE_FOR_SELECTED_DATE);
        const {serviceEndDateTime, invoiceSubmissionId} = payload;
        const {invoiceSubmissions} = yield select(state => state.invoiceSubmission);
        const invoiceSubmission = invoiceSubmissions[invoiceSubmissionId];
        const {assignment} = invoiceSubmission;
        const {member} = assignment;

        const contractPartnerId = assignment?.contractPartnerId || assignment?.contractPartnerInfo?.id;
        if (!contractPartnerId) {
            continue;
        }

        yield fork(getPricingInfoFlow, {
            payload: {
                contractPartnerId: contractPartnerId,
                serviceEndDateTime: serviceEndDateTime || moment().format(),
                type: apsPricingTypes.CONTRACT_PARTNER_FIXED_PRICE,
            },
        });

        const searchQueryParams = new URLSearchParams();
        searchQueryParams.append('validOn', moment(serviceEndDateTime).format('YYYY-MM-DD'));
        searchQueryParams.set('type', apsPricingTypes.SERVICE_FIXED_PRICE);

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.GET_SERVICE_FIXED_PRICE_REQUEST,
            pricingManagementService.getValidPrice,
            {searchQueryParams},
        );

        const serviceFixedPriceResponse = yield take([
            invoiceSubmissionActionTypes.GET_SERVICE_FIXED_PRICE_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.GET_SERVICE_FIXED_PRICE_REQUEST_FAILED,
        ]);

        if (!serviceFixedPriceResponse.error) {
            const {response} = serviceFixedPriceResponse.payload;
            const {pricePeriodDTOs} = response;

            yield put({
                type: invoiceSubmissionActionTypes.STORE_SERVICE_FIXED_PRICES,
                payload: {pricePeriodDTOs},
            });
        }

        searchQueryParams.set('type', apsPricingTypes.OPENING_MAX_PRICE);
        searchQueryParams.set('memberTariff', member ? member.tariffDetails?.tariffGroup || 'CLASSIC' : 'CLASSIC');

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.GET_OPEN_CAR_MAX_PRICE_REQUEST,
            pricingManagementService.getValidPrice,
            {searchQueryParams},
        );

        const openCarMaxPriceResponse = yield take([
            invoiceSubmissionActionTypes.GET_OPEN_CAR_MAX_PRICE_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.GET_OPEN_CAR_MAX_PRICE_REQUEST_FAILED,
        ]);

        if (!openCarMaxPriceResponse.error) {
            const {response} = serviceFixedPriceResponse.payload;
            const {pricePeriodDTOs} = response;
            const openCarMaxPeriod = pricePeriodDTOs[0];

            yield put({
                type: invoiceSubmissionActionTypes.STORE_OPEN_CAR_MAX_PRICES,
                payload: {openCarMaxPrice: openCarMaxPeriod?.value || null},
            });
        }
    }
};

export default getValidFixedPrices;
