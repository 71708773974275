import {immerable} from 'immer';
import Entity from '../entity/Entity';

/**
 * TariffDetails entity type definition
 *
 * @typedef {Object} TariffDetails
 * @property {?string} tariff
 * @property {?string} tariffGroup
 */

/**
 * TariffDetails entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const tariffDetailsEntityDTOMapping = {
    tariff: 'tariff',
    tariffGroup: 'tariffGroup',
};

/**
 * TariffDetails entity
 *
 * @class
 * @extends Entity
 * @type {TariffDetails}
 */
export default class TariffDetails extends Entity {
    [immerable] = true;

    static entityDTOMapping = tariffDetailsEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}

