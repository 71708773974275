import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import PropTypes from 'prop-types';
import * as invoiceSubmissionSelectors from './invoiceSubmissionSelectors';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';
import AppLayoutView from '../application/AppLayoutView';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import ViewIndicator from './view-elements/ViewIndicator';
import {ButtonPrimary, ToggleButton} from '../ui-components';
import {invoiceSubmissionViews} from './constants/invoiceSubmissionViews';
import Tooltip from '../ui-components/tooltip/Tooltip';
import InfoIcon from '../ui-components/assets/icons/info-red.svg';
import InvoiceSubmissionFormView from './InvoiceSubmissionFormView';
import SummaryView from './SummaryView';
import {getValidationErrors} from '../application/errorsSelector';
import './InvoiceSubmissionView.scss';
import {InvoiceSubmissionDataStates as InvoiceSubmissionDataState} from './constants/invoiceSubmissionDataStates';
import {eafStatuses} from '../ella-dispo-entity-types';

const shouldDisplayToggleButton = status => {
    return [
        eafStatuses.BILLABLE,
        eafStatuses.IN_EDITING,
        eafStatuses.EXTERNAL_INVOICING,
    ].indexOf(status) !== -1;
};

const setIsInvoiceSubmissionSubmitted = status => {
    return !([
        eafStatuses.BILLABLE,
        eafStatuses.IN_EDITING,
    ].indexOf(status) !== -1);
};


const InvoiceSubmissionView = props => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translateView = createTranslateShorthand('invoice_submission_view');
    const [isExternalEventScreen, setIsExternalEventScreen] = useState(false);
    const {invoiceSubmission, invoiceSubmissionViewState, invoiceSubmissionDataState, formAnchor} = props;
    const {pricingInfo, errors, isPricingDataReady, changeExternalStatus} = props;
    const isToggleButtonShown = invoiceSubmission ? shouldDisplayToggleButton(invoiceSubmission.status) : false;
    const isInvoiceSubmissionSubmitted = invoiceSubmission
        ? setIsInvoiceSubmissionSubmitted(invoiceSubmission.status) : false;
    const isInvoiceSubmissionStatusRequest = invoiceSubmission
        && invoiceSubmission.status === eafStatuses.REQUEST;
    const isInvoiceSubmissionRequestedSubService = invoiceSubmission
            && isInvoiceSubmissionStatusRequest
            && !invoiceSubmission.invoiceSubmissionId.endsWith('A1');
    const changingOnExternalStatus = () => {
        changeExternalStatus({
            invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId,
            enable: true,
        });
    };

    return (
        <AppLayoutView>
            {invoiceSubmissionDataState !== InvoiceSubmissionDataState.LOADING && isPricingDataReady ? (
                <div className="invoice-submission-wrapper-view">
                    <div className="invoice-submission-wrapper-view__title">
                        <h3 className="ace-h3">{translateView('header.title')} {invoiceSubmission.name}</h3>
                    </div>
                    <ViewIndicator viewState={invoiceSubmissionViewState} />
                    {isToggleButtonShown && (
                        <div className="ace-grid__row">
                            <div className="col-xs--12">
                                <div className="ace-edit__toggle-wrapper">
                                    <div className="ace-edit__toggle-container">
                                        <ToggleButton
                                            onChange={() => setIsExternalEventScreen(!isExternalEventScreen)}
                                            isChecked={isExternalEventScreen
                                            || invoiceSubmissionViewState === invoiceSubmissionViews.EXTERNAL}
                                            label={translateView('toggle_switch_label.is_external_event_screen')}
                                            isDisabled={invoiceSubmissionViewState === invoiceSubmissionViews.EXTERNAL}
                                        />
                                        <Tooltip
                                            className="ace-edit__tooltip-container"
                                            message={translateView('tooltip_message.is_external_event_screen')}
                                            position="right"
                                        >
                                            <img
                                                className="overview-price__internal-invoice-icon"
                                                src={InfoIcon}
                                                alt=""
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isExternalEventScreen && invoiceSubmissionViewState === invoiceSubmissionViews.FORM && (
                        <InvoiceSubmissionFormView
                            pricingInfo={pricingInfo}
                            invoiceSubmissionDataState={invoiceSubmissionDataState}
                            errors={errors}
                            formAnchor={formAnchor}
                            isInvoiceSubmissionSubmitted={isInvoiceSubmissionSubmitted}
                            isInvoiceSubmissionStatusRequest={isInvoiceSubmissionStatusRequest}
                            isInvoiceSubmissionRequestedSubService={isInvoiceSubmissionRequestedSubService}
                        />
                    )}
                    {!isExternalEventScreen && (invoiceSubmissionViewState === invoiceSubmissionViews.SUMMARY
                        || invoiceSubmissionViewState === invoiceSubmissionViews.MEMBER_FORM
                        || invoiceSubmissionViewState === invoiceSubmissionViews.VEHICLE_FORM) && (
                        <SummaryView
                            isInvoiceSubmissionSubmitted={isInvoiceSubmissionSubmitted}
                            isInvoiceSubmissionStatusRequest={isInvoiceSubmissionStatusRequest}
                            invoiceSubmission={invoiceSubmission}
                            invoiceSubmissionDataState={invoiceSubmissionDataState}
                            invoiceSubmissionViewState={invoiceSubmissionViewState}
                            isInvoiceSubmissionRequestedSubService={isInvoiceSubmissionRequestedSubService}
                            errors={errors}
                        />
                    )}
                    {(isExternalEventScreen || invoiceSubmissionViewState === invoiceSubmissionViews.EXTERNAL) && (
                        <div className="ace-edit__message">
                            <p className="ace-copy-m ace-copy-m--center">
                                <span>{translateView('text.is_external_event_screen_first_part')}
                                </span><br />
                                <span>{translateView('text.is_external_event_screen_second_part')}
                                </span>
                            </p>
                            {invoiceSubmissionViewState !== invoiceSubmissionViews.EXTERNAL && (
                                <div className="ace-edit__message-exit-btn">
                                    <ButtonPrimary
                                        label={translateView('button_label.complete')}
                                        name="Abschließen"
                                        onClick={changingOnExternalStatus}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <ScreenMessage messageParagraphs={[translate('global.screen_message.loading')]} isLoading />
            )}
        </AppLayoutView>
    );
};

InvoiceSubmissionView.propTypes = {
    invoiceSubmission: PropTypes.object,
    invoiceSubmissionViewState: PropTypes.string,
    invoiceSubmissionDataState: PropTypes.string,
    formAnchor: PropTypes.string,
    pricingInfo: PropTypes.number,
    errors: PropTypes.object,
    isPricingDataReady: PropTypes.bool.isRequired,
    changeExternalStatus: PropTypes.func.isRequired,
};

InvoiceSubmissionView.defaultProps = {
    invoiceSubmission: {},
    invoiceSubmissionViewState: '',
    pricingInfo: null,
    errors: {},
    formAnchor: '',
    invoiceSubmissionDataState: InvoiceSubmissionDataState.LOADING,
};

const mapStateToProps = (state, props) => {
    const getInvoiceSubmission = invoiceSubmissionSelectors.createInvoiceSubmissionSelector();
    return {
        invoiceSubmission: getInvoiceSubmission(state, props),
        invoiceSubmissionViewState: state.invoiceSubmission.invoiceSubmissionViewState,
        invoiceSubmissionDataState: state.invoiceSubmission.invoiceSubmissionDataState,
        formAnchor: state.invoiceSubmission.formAnchor,
        errors: getValidationErrors(state),
        pricingInfo: state.invoiceSubmission.pricingInfo,
        isPricingDataReady: state.invoiceSubmission.isPricingDataReady,
    };
};

const mapDispatchToProps = dispatch => ({
    changeExternalStatus: payload => dispatch({
        type: invoiceSubmissionActionTypes.START_UPDATE_EXTERNAL_STATUS_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSubmissionView);
