import {eventChannel} from 'redux-saga';
import {select, take} from 'redux-saga/effects';
import keycloakAuth from '../../keycloakAuth';
import supportFiles from '../../supportFiles';
import invoiceSubmissionFiles from '../../invoiceSubmissionFiles';

const provideKeycloakTokenToServicesFlow = function* provideKeycloakTokenToServicesFlow() {
    const {serviceManager} = yield select(state => state.application);

    const authSessionEventChannel = eventChannel(emitter => {
        // returns unsubscribe method to eventChannel
        return keycloakAuth.onAuthSessionStarted(sessionCredentials => {
            emitter({sessionCredentials});
        });
    });

    while (true) {
        const {sessionCredentials} = yield take(authSessionEventChannel);
        try {
            const userProfileService = serviceManager.loadService('userManagementService');
            userProfileService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
        // no-op
        }

        try {
            const DAFService = serviceManager.loadService('driverAssignmentFlowService');
            DAFService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
        // no-op
        }

        try {
            const DLService = serviceManager.loadService('driverLocatorService');
            DLService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
        // no-op
        }

        try {
            const APMService = serviceManager.loadService('partnerManagementService');
            APMService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
        // no-op
        }


        try {
            const memberProfileService = serviceManager.loadService('memberProfileService');
            memberProfileService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
        // no-op
        }

        try {
            const ellaAssignmentFlowService = serviceManager.loadService('ellaAssignmentFlowService');
            ellaAssignmentFlowService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
        // no-op
        }

        try {
            const pricingManagementService = serviceManager.loadService('pricingManagementService');
            pricingManagementService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }

        try {
            supportFiles.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }

        try {
            invoiceSubmissionFiles.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }
    }
};

export default provideKeycloakTokenToServicesFlow;
