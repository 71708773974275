import {fork, put, take, select} from 'redux-saga/effects';
import * as documentDownloadActionTypes from './documentDownloadActionTypes';
import fetchRequest from '../application/sagas/fetchRequest';
import * as userProfileSelectors from '../user-management/userManagementSelectors';

/**
 * Load documents
 */
export const loadDocuments = function* loadDocuments() {
    const userAccount = yield select(userProfileSelectors.getUserAccount);
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    yield put({
        type: documentDownloadActionTypes.STORE_DOCUMENTS,
        payload: {documentFileDTOs: null},
    });

    yield fork(fetchRequest, documentDownloadActionTypes.FETCH_DOCUMENTS_REQUEST, eafService.getFiles, {contractPartners: userAccount.partnerIds.join(',')});

    const resultAction = yield take([
        documentDownloadActionTypes.FETCH_DOCUMENTS_REQUEST_SUCCEEDED,
        documentDownloadActionTypes.FETCH_DOCUMENTS_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        const {data} = resultAction.payload.response;
        yield put({
            type: documentDownloadActionTypes.STORE_DOCUMENTS,
            payload: {documentFileDTOs: data},
        });
    }

    if (resultAction.error && resultAction.payload.error_code === 'NOT_FOUND') {
        yield put({
            type: documentDownloadActionTypes.STORE_DOCUMENTS,
            payload: {documentFileDTOs: []},
        });
    }
};
