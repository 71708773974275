import {put, fork, take, select} from 'redux-saga/effects';
import moment from 'moment';
import * as invoiceSubmissionActionTypes from '../../invoice-submission/invoiceSubmissionActionTypes';
import * as errorActions from '../../application/errorActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import {parseErrorMessages} from '../../utils/validationMessages';

const getPricingInfoFlow = function* getPricingInfoFlow({payload}) {
    yield put({
        type: invoiceSubmissionActionTypes.STORE_PRICING_INFO,
        payload: {pricingDTO: {flat: null}},
    });

    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {contractPartnerId, serviceEndDateTime, type} = payload;

    if (!serviceEndDateTime || !type) return;

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('validOn', moment(serviceEndDateTime).format('YYYY-MM-DD'));
    searchQueryParams.append('type', type);
    if (contractPartnerId) {
        searchQueryParams.append('contractPartner', contractPartnerId);
    }

    yield fork(
        fetchRequest,
        invoiceSubmissionActionTypes.GET_PRICING_INFO_REQUEST,
        pricingManagementService.getValidPrice,
        {searchQueryParams},
    );

    const resultAction = yield take([
        invoiceSubmissionActionTypes.GET_PRICING_INFO_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_PRICING_INFO_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        const {response} = resultAction.payload;
        const {pricePeriodDTOs} = response;
        const pricePeriodDTO = pricePeriodDTOs[0];

        yield put({
            type: invoiceSubmissionActionTypes.STORE_PRICING_INFO,
            payload: {pricingDTO: {flat: pricePeriodDTO?.value || 0}},
        });
        return;
    }
    yield put({
        type: errorActions.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(resultAction.payload),
            },
        },
    });
};

export default getPricingInfoFlow;
