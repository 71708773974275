import {AdditionalServices} from '../constants/additionalServices';
import {isElementInArray} from '../../utils/utils';

export const decodeAdditionalServices = additionalServices => {
    return additionalServices
        ? additionalServices.filter(value => {
            return value.type !== null
                && !!AdditionalServices[value.type]
                && !isElementInArray(value.type, [
                    AdditionalServices.SURCHARGE_TONNAGE_TOWING,
                    AdditionalServices.SURCHARGE_TONNAGE_PICK_UP,
                    AdditionalServices.SURCHARGE_TONNAGE_SALVAGE,
                ]);
        }).map(value => {
            const {subtotal, amount, ...otherData} = value;
            return {
                ...otherData,
                ...(subtotal && !amount
                    ? {amount: subtotal, subtotal}
                    : {subtotal, amount}
                ),
            };
        })
        : [];
};
